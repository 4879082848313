import { Box, Badge } from "@material-ui/core";
import {
  ActionLink,
  BackButton,
  DatePickerButton,
  Header,
  Icon,
  IconName,
  MobileSearchFieldButton,
} from "halifax";
import React from "react";
import { RouteComponentProps } from "react-router-dom";

import { PATH_HOME } from "../../../../utils/paths";

import { MobileEditLocationsModal } from "../MobileEditLocationsModal";
import { MobileLocationSelectionConnectorProps } from "./container";
import { MobileCalendarPickerModal } from "../MobileCalendarPickerModal";
import { TravelWalletDrawer } from "../../../travel-wallet/components";
import "./styles.scss";

interface IMobileLocationSelectionProps
  extends RouteComponentProps,
    MobileLocationSelectionConnectorProps {}

export const MobileLocationSelection = (
  props: IMobileLocationSelectionProps
) => {
  const {
    history,
    departureDate,
    returnDate,
    destination,
    setOpenDatesModal,
    walletItemCount,
  } = props;
  const [locationModalOpen, setLocationModalOpen] =
    React.useState<boolean>(false);
  const [openCalendarModal, setOpenCalendarModal] = React.useState(false);
  const [travelWalletDrawerOpen, setTravelWalletDrawerOpen] =
    React.useState<boolean>(false);

  const handleOpenCalendarModal = (open: boolean) => {
    setOpenCalendarModal(open);
    setOpenDatesModal(open);
  };

  return (
    <>
      <Header
        className={"mobile-pkg-hotel-availability-search-header"}
        left={
          <BackButton
            className="mobile-hotel-availability-go-back"
            onClick={() => history.push(PATH_HOME)}
          />
        }
        center={
          <Box className="mobile-hotel-availability-search-and-date">
            <MobileSearchFieldButton
              value={destination?.label}
              // value={isSearchingMap ? "Map area" : destination?.label}
              onClick={() => setLocationModalOpen(true)}
            />
            <DatePickerButton
              startDate={departureDate}
              endDate={returnDate}
              classes={["hotel-inline-juncture-input"]}
              renderCalendarPopup={() => (
                <MobileCalendarPickerModal
                  openCalendarModal={openCalendarModal}
                  setOpenCalendarModal={handleOpenCalendarModal}
                  fullScreen
                  searchOnContinue
                />
              )}
              onClick={() => handleOpenCalendarModal(true)}
              dateFormat={"MMM D"}
              variant="minimal"
            />
          </Box>
        }
        right={
          <ActionLink
            className="mobile-hotel-availability-wallet-entry"
            onClick={() => setTravelWalletDrawerOpen(true)}
            content={
              <Badge
                className="wallet-count"
                badgeContent={walletItemCount ? "" : undefined}
              >
                <Icon name={IconName.TravelWalletIcon} />
              </Badge>
            }
          />
        }
      />
      <MobileEditLocationsModal
        openLocationModal={locationModalOpen}
        setOpenLocationModal={setLocationModalOpen}
        setOpenCalendarModal={setOpenCalendarModal}
      />
      <TravelWalletDrawer
        drawerOpen={travelWalletDrawerOpen}
        setDrawerOpen={setTravelWalletDrawerOpen}
      />
    </>
  );
};
