import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { IStoreState } from "../../reducers/types";

import { PackagesFlightShop } from "./component";
import { getRewardsAccountWithLargestEarnForBanner } from "../rewards/reducer";
import { getFlightShopProgress, getIsFlightShopLoading } from "./reducer";
import {
  fetchPackagesFlightShop,
  setPackagesFlightShopProgress,
} from "./actions/actions";
import { getPackagesAvailabilityCallState } from "../availability/reducer";

const mapStateToProps = (state: IStoreState) => {
  return {
    largestValueAccount: getRewardsAccountWithLargestEarnForBanner(state),
    isFlightShopLoading: getIsFlightShopLoading(state),
    packagesAvailabilityCallState: getPackagesAvailabilityCallState(state),
    flightShopProgress: getFlightShopProgress(state),
  };
};

export const mapDispatchToProps = {
  fetchPackagesFlightShop,
  setPackagesFlightShopProgress,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type PackagesFlightShopConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedPackagesFlightShop = withRouter(
  connector(PackagesFlightShop)
);
