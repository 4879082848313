import { FlightError } from "@b2bportal/air-booking-api";
import {
  PaymentError,
  Product,
  ProductError,
  PurchaseError,
  PurchaseErrorEnum,
} from "@b2bportal/purchase-api";
import {
  EXPIRED_PASSPORT_SUBTITLE,
  GENERIC_ERROR_SUBTITLE,
  GENERIC_ERROR_TITLE,
  INFANT_KTN_SUBTITLE,
  INFANT_REDRESS_SUBTITLE,
  INVALID_PASSENGERS_SUBTITLE,
  INVALID_PASSENGERS_TITLE,
  INVALID_PASSPORT_NUMBER_SUBTITLE,
  LAP_INFANT_TOO_OLD_SUBTITLE,
  LAP_INFANTS_UNSUPPORTED_SUBTITLE,
  MALFORMED_KTN_SUBTITLE,
  MALFORMED_REDRESS_SUBTITLE,
  MIN_AGE_NOT_MET_SUBTITLE,
  MISSING_AIRLINE_LOCATOR_SUBTITLE,
  MISSING_CONTACT_INFO_TITLE,
  MISSING_NATIONALITY_SUBTITLE,
  MISSING_PASSPORT_SUBTITLE,
  NO_AVAILABILITY_SUBTITLE,
  NO_AVAILABILITY_TITLE,
  NO_CONTACT_INFO_SUBTITLE,
  PASSENGER_VALIDATION_TITLE,
  SEATED_INFANTS_UNSUPPORTED_SUBTITLE,
  TECHNICAL_ERROR_SUBTITLE,
  TOO_MANY_LAP_INFANTS_SUBTITLE,
  TOO_MANY_PASSENGERS_SUBTITLE,
  TOO_MANY_PASSENGERS_TITLE,
  UNABLE_TO_COMPLETE_BOOKING_TITLE,
  UPDATE_TRAVELERS_CTA_TEXT,
  UPGRADE_REQUIRED_TITLE,
  RATE_NOT_AVAILABLE_TITLE,
  RATE_NOT_AVAILABLE_SUBTITLE,
  LACK_OF_INVENTORY_TITLE,
  LACK_OF_INVENTORY_SUBTITLE,
  CHECK_IN_AGE_NOT_MET_TITLE,
  CHECK_IN_AGE_NOT_MET_SUBTITLE,
  PRICE_INCREASE_TITLE,
  CONTINUE_CTA_TEXT,
  SELECT_ANOTHER_PACKAGE_CTA_TEXT,
  PRICE_DECREASE_TITLE,
  TRY_AGAIN_CTA_TEXT,
  BOOKING_FAILED_TITLE,
  PRICE_CHANGE_SUBTITLE,
  GENERIC_ERROR_TRY_AGAIN_SUBTITLE,
} from "./textConstants";
import { IGenericInfoPopupProps } from "halifax/build/GenericInfoPopup";
import {
  CartFulfillErrorModal,
  CartQuoteErrorModal,
  PriceChangeType,
} from "@capone/checkout";
import { HotelBookErrorType } from "redmond";
import { Icon, IconName } from "halifax";
import React from "react";

const UPDATE_TRAVELERS_BUTTON = (
  onClick: () => void
): IGenericInfoPopupProps["buttons"] => [
  {
    buttonText: UPDATE_TRAVELERS_CTA_TEXT,
    onClick,
  },
];

const SELECT_ANOTHER_PACKAGE_BUTTON = (
  onClick: () => void
): IGenericInfoPopupProps["buttons"] => [
  {
    buttonText: SELECT_ANOTHER_PACKAGE_CTA_TEXT,
    onClick,
  },
];

const DEFAULT_ERROR_MODEL_PROPS = (
  onClick?: () => void
): Pick<IGenericInfoPopupProps, "title" | "subtitle" | "buttons"> => ({
  title: GENERIC_ERROR_TITLE,
  subtitle: GENERIC_ERROR_SUBTITLE,
  buttons: onClick ? SELECT_ANOTHER_PACKAGE_BUTTON(onClick) : [],
});

const getFlightError = (
  flightError: FlightError,
  updateTravelers: () => void,
  goToAvailability: () => void
): Pick<IGenericInfoPopupProps, "title" | "subtitle" | "buttons"> => {
  switch (flightError) {
    case FlightError.CheckInMinimumAgeNotMet:
      return {
        title: INVALID_PASSENGERS_TITLE,
        subtitle: MIN_AGE_NOT_MET_SUBTITLE,
        buttons: UPDATE_TRAVELERS_BUTTON(updateTravelers),
      };
    case FlightError.ExpiredPassport:
      return {
        title: PASSENGER_VALIDATION_TITLE,
        subtitle: EXPIRED_PASSPORT_SUBTITLE,
        buttons: UPDATE_TRAVELERS_BUTTON(updateTravelers),
      };
    case FlightError.IllegalLapInfantKTN:
      return {
        title: INVALID_PASSENGERS_TITLE,
        subtitle: INFANT_KTN_SUBTITLE, // todo: we don't have the pax name
        buttons: UPDATE_TRAVELERS_BUTTON(updateTravelers),
      };
    case FlightError.IllegalLapInfantRedress:
      return {
        title: INVALID_PASSENGERS_TITLE,
        subtitle: INFANT_REDRESS_SUBTITLE, // todo: we don't have the pax name
        buttons: UPDATE_TRAVELERS_BUTTON(updateTravelers),
      };
    case FlightError.InvalidCustomer:
      return {
        title: INVALID_PASSENGERS_TITLE,
        subtitle: INVALID_PASSENGERS_SUBTITLE,
        buttons: UPDATE_TRAVELERS_BUTTON(updateTravelers),
      };
    case FlightError.InvalidPassengers:
      return {
        title: INVALID_PASSENGERS_TITLE,
        subtitle: INVALID_PASSENGERS_SUBTITLE,
        buttons: UPDATE_TRAVELERS_BUTTON(updateTravelers),
      };
    case FlightError.InvalidPassportNumber:
      return {
        title: PASSENGER_VALIDATION_TITLE,
        subtitle: INVALID_PASSPORT_NUMBER_SUBTITLE,
        buttons: UPDATE_TRAVELERS_BUTTON(updateTravelers),
      };
    case FlightError.LapInfantTooOld:
      return {
        title: INVALID_PASSENGERS_TITLE,
        subtitle: LAP_INFANT_TOO_OLD_SUBTITLE,
        buttons: UPDATE_TRAVELERS_BUTTON(updateTravelers),
      };
    case FlightError.LapInfantsUnsupported:
      return {
        title: INVALID_PASSENGERS_TITLE,
        subtitle: LAP_INFANTS_UNSUPPORTED_SUBTITLE,
        buttons: UPDATE_TRAVELERS_BUTTON(updateTravelers),
      };
    case FlightError.MalformedKnownTravelerNumber:
      return {
        title: PASSENGER_VALIDATION_TITLE,
        subtitle: MALFORMED_KTN_SUBTITLE,
        buttons: UPDATE_TRAVELERS_BUTTON(updateTravelers),
      };
    case FlightError.MalformedRedressNumber:
      return {
        title: PASSENGER_VALIDATION_TITLE,
        subtitle: MALFORMED_REDRESS_SUBTITLE,
        buttons: UPDATE_TRAVELERS_BUTTON(updateTravelers),
      };
    case FlightError.MissingAirlineLocator:
      return {
        title: UNABLE_TO_COMPLETE_BOOKING_TITLE,
        subtitle: MISSING_AIRLINE_LOCATOR_SUBTITLE,
        buttons: SELECT_ANOTHER_PACKAGE_BUTTON(goToAvailability),
      };
    case FlightError.MissingNationality:
      return {
        title: INVALID_PASSENGERS_TITLE,
        subtitle: MISSING_NATIONALITY_SUBTITLE,
        buttons: UPDATE_TRAVELERS_BUTTON(updateTravelers),
      };
    case FlightError.MissingPassport:
      return {
        title: PASSENGER_VALIDATION_TITLE,
        subtitle: MISSING_PASSPORT_SUBTITLE,
        buttons: UPDATE_TRAVELERS_BUTTON(updateTravelers),
      };
    case FlightError.MissingPassportNumber:
      return {
        title: PASSENGER_VALIDATION_TITLE,
        subtitle: MISSING_PASSPORT_SUBTITLE,
        buttons: UPDATE_TRAVELERS_BUTTON(updateTravelers),
      };
    case FlightError.NoAvailability:
      return {
        title: NO_AVAILABILITY_TITLE,
        subtitle: NO_AVAILABILITY_SUBTITLE,
        buttons: SELECT_ANOTHER_PACKAGE_BUTTON(goToAvailability),
      };
    case FlightError.NoAdultPassenger:
      return {
        title: INVALID_PASSENGERS_TITLE,
        subtitle: MIN_AGE_NOT_MET_SUBTITLE,
        buttons: UPDATE_TRAVELERS_BUTTON(updateTravelers),
      };
    case FlightError.NoContactInformation:
      return {
        title: MISSING_CONTACT_INFO_TITLE,
        subtitle: NO_CONTACT_INFO_SUBTITLE,
        buttons: UPDATE_TRAVELERS_BUTTON(updateTravelers),
      };
    case FlightError.NoTicketlessResponse:
      return {
        title: UNABLE_TO_COMPLETE_BOOKING_TITLE,
        subtitle: TECHNICAL_ERROR_SUBTITLE,
        buttons: UPDATE_TRAVELERS_BUTTON(updateTravelers),
      };
    case FlightError.ProviderError:
      return {
        title: UNABLE_TO_COMPLETE_BOOKING_TITLE,
        subtitle: TECHNICAL_ERROR_SUBTITLE,
        buttons: UPDATE_TRAVELERS_BUTTON(updateTravelers),
      };
    case FlightError.SeatedInfantsUnsupported:
      return {
        title: INVALID_PASSENGERS_TITLE,
        subtitle: SEATED_INFANTS_UNSUPPORTED_SUBTITLE,
        buttons: UPDATE_TRAVELERS_BUTTON(updateTravelers),
      };
    case FlightError.TooManyLapInfants:
      return {
        title: INVALID_PASSENGERS_TITLE,
        subtitle: TOO_MANY_LAP_INFANTS_SUBTITLE,
        buttons: UPDATE_TRAVELERS_BUTTON(updateTravelers),
      };
    case FlightError.TooManyPassengers:
      return {
        title: TOO_MANY_PASSENGERS_TITLE,
        subtitle: TOO_MANY_PASSENGERS_SUBTITLE,
        buttons: UPDATE_TRAVELERS_BUTTON(updateTravelers),
      };
    case FlightError.TransientServiceError:
      return {
        title: UNABLE_TO_COMPLETE_BOOKING_TITLE,
        subtitle: TECHNICAL_ERROR_SUBTITLE,
        buttons: SELECT_ANOTHER_PACKAGE_BUTTON(goToAvailability),
      };
    case FlightError.UnknownSabreAppError:
      return {
        title: UNABLE_TO_COMPLETE_BOOKING_TITLE,
        subtitle: TECHNICAL_ERROR_SUBTITLE,
        buttons: SELECT_ANOTHER_PACKAGE_BUTTON(goToAvailability),
      };
    case FlightError.UpgradeRequired:
      return {
        title: UPGRADE_REQUIRED_TITLE,
        subtitle: TECHNICAL_ERROR_SUBTITLE,
        buttons: SELECT_ANOTHER_PACKAGE_BUTTON(goToAvailability),
      };
    default:
      // default cover FlightErrors that should not occur in PriceQuote
      return DEFAULT_ERROR_MODEL_PROPS(goToAvailability);
  }
};

const getHotelError = (
  hotelError: HotelBookErrorType,
  updateTravelers: () => void,
  goToAvailability: () => void
): Pick<IGenericInfoPopupProps, "title" | "subtitle" | "buttons"> => {
  switch (hotelError) {
    case HotelBookErrorType.RateNotAvailable:
      return {
        title: RATE_NOT_AVAILABLE_TITLE,
        subtitle: RATE_NOT_AVAILABLE_SUBTITLE,
        buttons: SELECT_ANOTHER_PACKAGE_BUTTON(goToAvailability),
      };
    case HotelBookErrorType.LackOfInventory:
      return {
        title: LACK_OF_INVENTORY_TITLE,
        subtitle: LACK_OF_INVENTORY_SUBTITLE,
        buttons: SELECT_ANOTHER_PACKAGE_BUTTON(goToAvailability),
      };
    case HotelBookErrorType.CheckInMinimumAgeNotMet:
      return {
        title: CHECK_IN_AGE_NOT_MET_TITLE,
        subtitle: CHECK_IN_AGE_NOT_MET_SUBTITLE,
        buttons: UPDATE_TRAVELERS_BUTTON(updateTravelers),
      };
    default:
      return DEFAULT_ERROR_MODEL_PROPS(goToAvailability);
  }
};

// const getAirPriceFreezeError = (pfError: PriceFreezeError) => {
//   switch (pfError.PriceFreezeError) {
//     case PriceFreezeErrorEnum.NotEnoughSeats:
//       return DEFAULT_ERROR_MODEL_PROPS;
//     case PriceFreezeErrorEnum.OfferNotFound:
//       return DEFAULT_ERROR_MODEL_PROPS;
//     case PriceFreezeErrorEnum.PriceFreezeErrorCode:
//       return DEFAULT_ERROR_MODEL_PROPS;
//     case PriceFreezeErrorEnum.ProductNotFound:
//       return DEFAULT_ERROR_MODEL_PROPS;
//     default:
//       return DEFAULT_ERROR_MODEL_PROPS;
//   }
// };

const getProductError = (
  error: ProductError,
  updateFlightTravelers: () => void,
  updateHotelTravelers: () => void,
  goToAvailability: () => void
) => {
  switch (error.value.type) {
    // case Product.AirPriceFreeze: {
    //   const pfError = error.value.value as PriceFreezeError;
    //   return getAirPriceFreezeError(pfError);
    // }
    case Product.Flight: {
      const flightError = error.value.value as FlightError;
      return getFlightError(
        flightError,
        updateFlightTravelers,
        goToAvailability
      );
    }
    case Product.Seats: {
      // https://github.com/hopper-org/b2b-interfaces/blob/8316205c08da3b4405647ff5245f3bdc76f0ef73/purchase/src/main/scala/com/hopper/b2b/purchase/Models.scala#L114
      const flightError = error.value.value as FlightError;
      return getFlightError(
        flightError,
        updateFlightTravelers,
        goToAvailability
      );
    }
    case Product.Hotel: {
      const hotelError = error.value.value.LodgingError as HotelBookErrorType;
      return getHotelError(hotelError, updateHotelTravelers, goToAvailability);
    }
    default:
      return DEFAULT_ERROR_MODEL_PROPS(goToAvailability);
  }
};

const getPaymentError = (_error: PaymentError, onClick?: () => void) => {
  return DEFAULT_ERROR_MODEL_PROPS(onClick);
};

export const getErrorModalProps = (
  type?: CartQuoteErrorModal | CartFulfillErrorModal,
  data?: PurchaseError[],
  priceChange?: PriceChangeType,
  isQuote?: boolean,
  isFulfill?: boolean,
  updateFlightTravelers?: () => void,
  updateHotelTravelers?: () => void,
  handleAcknowledgePriceChange?: () => void,
  retryQuote?: () => void,
  goToAvailability?: () => void
): Pick<IGenericInfoPopupProps, "title" | "subtitle" | "buttons" | "image"> => {
  if (!type && !!priceChange?.hasDifference && !!priceChange.difference) {
    if (priceChange.isIncrease) {
      return {
        title: PRICE_INCREASE_TITLE(priceChange.difference),
        subtitle: PRICE_CHANGE_SUBTITLE,
        image: <Icon className="error-icon" name={IconName.PriceIncrease} />,
        buttons: [
          {
            buttonText: CONTINUE_CTA_TEXT,
            onClick: handleAcknowledgePriceChange,
          },
          {
            buttonText: SELECT_ANOTHER_PACKAGE_CTA_TEXT,
            onClick: goToAvailability,
            defaultStyle: "h4r-secondary",
          },
        ],
      };
    } else {
      return {
        title: PRICE_DECREASE_TITLE(priceChange.difference),
        subtitle: PRICE_CHANGE_SUBTITLE,
        image: <Icon className="error-icon" name={IconName.PriceDrop} />,
        buttons: [
          {
            buttonText: CONTINUE_CTA_TEXT,
            onClick: handleAcknowledgePriceChange,
          },
        ],
      };
    }
  }

  const handleDefaultError = () => {
    switch (true) {
      case isQuote:
        return {
          title: GENERIC_ERROR_TITLE,
          subtitle: GENERIC_ERROR_TRY_AGAIN_SUBTITLE,
          buttons: [
            { buttonText: TRY_AGAIN_CTA_TEXT, onClick: retryQuote },
            {
              buttonText: SELECT_ANOTHER_PACKAGE_CTA_TEXT,
              onClick: goToAvailability,
              defaultStyle: "h4r-secondary",
            },
          ],
        };
      case isFulfill:
        return {
          title: BOOKING_FAILED_TITLE,
          buttons: [
            {
              buttonText: SELECT_ANOTHER_PACKAGE_CTA_TEXT,
              onClick: goToAvailability,
            },
          ],
        };
      default:
        return DEFAULT_ERROR_MODEL_PROPS(goToAvailability);
    }
  };

  switch (type) {
    case CartFulfillErrorModal.FailedPolling:
    case CartQuoteErrorModal.FailedPolling: {
      const head = data?.[0];
      switch (head?.Error) {
        case PurchaseErrorEnum.NoAvailability:
        case PurchaseErrorEnum.ExpiredFulfillToken:
          return {
            title: NO_AVAILABILITY_TITLE,
            subtitle: NO_AVAILABILITY_SUBTITLE,
            buttons: SELECT_ANOTHER_PACKAGE_BUTTON(
              goToAvailability || (() => {})
            ),
          };
        case PurchaseErrorEnum.PaymentError:
          return getPaymentError(head as PaymentError, goToAvailability);
        case PurchaseErrorEnum.ProductError:
          return getProductError(
            head as ProductError,
            updateFlightTravelers || (() => {}),
            updateHotelTravelers || (() => {}),
            goToAvailability || (() => {})
          );
        default:
          return handleDefaultError();
      }
    }
    default:
      return handleDefaultError();
  }
};
