import React from "react";

export const FIND_MORE_RESULTS_TITLE_TEXT = "Find more hotels";
export const FIND_MORE_RESULTS_SUBTITLE_TEXT =
  "Adjust your dates or search again to view more hotels.";
export const PREMIUM_STAYS_FIND_MORE_RESULTS_TITLE_TEXT = (
  <div style={{ lineHeight: 1.2 }}>
    <span className="font-bold">You’ve reached the end</span> of all premium
    stays in this location.
  </div>
);
export const PREMIUM_STAYS_FIND_MORE_RESULTS_SUBTITLE_TEXT = "";
export const ADJUST_DATES_TEXT = "Adjust dates";
export const RESET_FILTERS_TEXT = "Reset filters";
export const SEARCH_AGAIN_TEXT = "Search again";
export const CLEAR_FILTERS_AND_SEARCH = "Clear filters and view all hotels";
export const HOTEL_COUNT_HEADING_TEXT = (
  hotelCount: number,
  location: string,
  isLodgingSearch?: boolean,
  isSearchTermPoint?: boolean,
  isSearchingMap?: boolean
) => (
  <>
    Viewing <strong>{hotelCount} hotels</strong>{" "}
    {isSearchingMap
      ? `in map area`
      : `${isLodgingSearch || isSearchTermPoint ? `near` : `in`} ${location}`}
  </>
);

export const getEarnTagText = (earnRate: string | number, currency: string) => {
  const multiplier = currency.includes("cash") ? "%" : "X";
  return `<span class="font-bold">Earn ${earnRate}${multiplier}</span> ${currency} on hotels`;
};

export const CORP_COMPARE_TO_BAR_BANNER_TEXT = (
  <>
    <strong>Save up to 10%</strong> on select hotel bookings with our corporate
    rates.
  </>
);

export const RECOMMENDED_FLIGHTS_HEADER = (
  <>
    Package prices include this <strong>recommended</strong> flight:
  </>
);

export const SEARCHED_FARE_CLASS_NOT_FOUND_BANNER_TEXT =
  "We can’t find any packages that match your fare class selection. You can try another search or see the full list of results below.";

export const NO_NONSTOP_FLIGHTS_FOUND =
  "We can’t find any packages that include nonstop flights. You can try another search or see the full list of results below.";
