import dayjs from "dayjs";
import { Airport } from "redmond";
import React from "react";

const OUTBOUND = "Outbound";
const RETURN = "Return";
// const FLIGHT = "Flight";

export const getReviewCardHeaderWithType = (
  isDeparture: boolean,
  location: string, // destinationCode
  date: Date | string,
  airports?: Airport[]
) => {
  const airportName = airports?.find((airport) => airport.code === location);
  return {
    type: isDeparture ? OUTBOUND : RETURN,
    description: ` to ${airportName?.servedCityName ?? location} ${
      !!airportName ? `(${location})` : ""
    } on ${dayjs(date).format("ddd, MMM D")}`,
  };
};

export const getStopsString = (stops: number) =>
  stops === 0 ? "Nonstop" : stops > 1 ? `${stops} stops` : "1 stop";

export const RECOMMENDED_FLIGHTS = "Recommended flights";
export const INFO_HEADER = (
  <>
    We <strong>recommend</strong> this flight for your package
  </>
);
export const HOW_IT_WORKS = "How it works";
export const HOW_IT_WORKS_INFO_POINTS = [
  "In order to show you a total package price, this recommended flight is included in all hotel results.",
  "You can select this recommended flight, or select any other available flight after you select a hotel.",
  "Total prices will vary based on your final flight selection.",
];

export const CHOOSE_DIFF_FLIGHTS =
  "You can choose a different flight after you choose a hotel. ";
export const LEARN_MORE = "Learn more";

export const OPERATING_AIRLINES_MODAL_TITLE = "Operating airlines";
export const OPERATING_AIRLINES_TOOLTIP_TRIGGER_TEXT = (airlineCount: number) =>
  `+${airlineCount} more`;
