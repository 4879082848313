import { assign, DoneInvokeEvent } from "xstate";
import {
  cartHelpers,
  getHasPriceChanged,
  ParentState,
  PriceChangeType,
} from "@capone/checkout";
import {
  ProductOpaqueValue,
  Product,
  QuoteSuccess,
  Payment,
} from "@b2bportal/purchase-api";
import { PackageOpaqueProductValue, PackagesMachineContext } from "../types";
import { InitializeCheckoutStateEvent, SetPlatformEvent } from "../events";
import { RoomInfoProducts, Lodging } from "@b2bportal/lodging-api";
import { PaymentOpaqueValue } from "@b2bportal/air-booking-api";
import { getOpaquePayments, getPackagesShopPricing } from "../selectors/common";
import { ITripTerminus } from "redmond";

export const setPaymentFulfillParams = assign(
  (ctx: PackagesMachineContext, _event) => {
    const opaquePayments = getOpaquePayments({ context: ctx });

    ctx[ParentState.cartFulfill].fulfillRequestPayments?.push(
      ...opaquePayments
    );

    return ctx;
  }
);

export const initializeCheckoutState = assign(
  (ctx: PackagesMachineContext, event: InitializeCheckoutStateEvent) => {
    ctx.flightShop.selectedTrip = event.payload.selectedTrip;
    ctx.flightShop.tripDetails = event.payload.tripDetails;
    ctx.lodgingShop.fromDate = event.payload.hotelFromDate;
    ctx.lodgingShop.untilDate = event.payload.hotelUntilDate;
    ctx.lodgingShop.selectedLodging = event.payload
      .selectedLodging as unknown as Lodging;
    ctx.lodgingShop.selectedRoom = event.payload
      .selectedRoom as unknown as RoomInfoProducts;
    ctx.lodgingShop.selectedRoomRateId =
      event.payload.selectedRoom.products[0]?.rateId?.value;
    ctx.finalizePackageResponse = event.payload.finalizePackageResponse;
    ctx.packagePricing = event.payload.packagePricing;
    ctx.lodgingShop.guests = {
      adults: event.payload.searchedAdultsCount,
      children: [
        ...event.payload.searchedChildren,
        ...event.payload.searchedInfants,
      ],
    };
    ctx.searchedDestination = event.payload.searchedDestination;
    ctx.flightSearch.departureDate = event.payload.searchedDepartureDate;
    ctx.flightSearch.returnDate = event.payload.searchedReturnDate;
    ctx.flightSearch.destination = event.payload
      .searchedDestination as ITripTerminus;
    ctx.flightSearch.origin = event.payload.searchedOrigin;

    return ctx;
  }
);

export const addPackageProduct = assign(
  (ctx: PackagesMachineContext, _event: unknown) => {
    const packageProduct: ProductOpaqueValue = {
      type: "Package",
      value: {
        destinationName:
          ctx.searchedDestination?.label ||
          `${ctx[ParentState.lodgingShop].selectedLodging?.lodging.city}, ${
            ctx[ParentState.lodgingShop].selectedLodging?.lodging.country
          }`,
        savings: ctx.packagePricing?.totalPackageSavings?.fiat,
      },
    };
    const ctxWithPackage = cartHelpers.addQuoteProduct(packageProduct, ctx);

    return ctxWithPackage;
  }
);

export const addPackageDiscount = assign(
  (ctx: PackagesMachineContext, _event: unknown) => {
    let packageSavings = ctx.packagePricing?.totalPackageSavings?.fiat?.value;
    if (packageSavings && packageSavings > 0) {
      const packageDiscount: PaymentOpaqueValue = {
        type: "PackageSyntheticDiscount" as any, // TODO: remove 'any' cast once Payment model is updated with PackageSyntheticDiscount
        value: {},
      };

      ctx[ParentState.cartUpdate].addPayments = [
        ...(ctx[ParentState.cartUpdate].addPayments.filter(
          (payment) => payment.type !== ("PackageSyntheticDiscount" as any)
        ) ?? []),
        packageDiscount,
      ];
    }

    return ctx;
  }
);

export const setPlatform = assign(
  (ctx: PackagesMachineContext, event: SetPlatformEvent) => {
    ctx.platform = event.platform;

    return ctx;
  }
);

export const checkForPriceChange = assign(
  (context: PackagesMachineContext, event: DoneInvokeEvent<QuoteSuccess>) => {
    const packagesShopPricing = getPackagesShopPricing({ context });
    const quoteBreakdown = event.data?.quoteBreakdown;

    if (!quoteBreakdown) return context;

    const quotedPackageProduct = quoteBreakdown?.products.find(
      (product) => product.product.type === Product.Package
    );

    const quotedPackageProductValue = quotedPackageProduct?.product.value as
      | PackageOpaqueProductValue
      | undefined;

    const quotePackageDiscountPayment = quoteBreakdown?.payments.find(
      (payment) =>
        payment.payment.type === Payment.Wallet &&
        payment.payment.value.offerName === "PackageSyntheticDiscount"
    );

    const quotePackageDiscount = quotePackageDiscountPayment?.payment.value as
      | {
          amount: {
            amount: number;
            currency: string;
          };
          offerName: "PackageSyntheticDiscount";
        }
      | undefined;

    const packageDiscountAmount =
      quotePackageDiscount?.amount.amount ||
      packagesShopPricing?.totalPackageSavings.fiat.value ||
      0;

    const travelerCount =
      context.passengerInformation.selectedPassengerIds.length +
      context.passengerInformation.selectedLapInfantIds.length;

    const packageDiscountAmountPerTraveler =
      packageDiscountAmount / travelerCount;

    const predictedTotal =
      packagesShopPricing?.pricePerTraveler.fiat.value || 0;
    const priceQuoteTotal =
      (quotedPackageProductValue?.perTravelerPricing.tripTotal.fiat.value ||
        0) - packageDiscountAmountPerTraveler;

    const difference = Math.abs(priceQuoteTotal - predictedTotal);

    const allowedValue = 1;
    const allowedCurrency = "USD";
    const allowedDifference = {
      value: allowedValue,
      currencyCode: allowedCurrency,
    };

    const actualDifference = {
      value: difference,
      currencyCode:
        quotedPackageProductValue?.perTravelerPricing.subtotal.fiat
          .currencyCode || "USD",
    };
    const hasDifference = getHasPriceChanged(
      allowedDifference,
      actualDifference
    );

    const differenceString = difference.toFixed();
    const priceChange: PriceChangeType = {
      hasDifference,
      predictedTotal,
      priceQuoteTotal,
      ...(hasDifference
        ? {
            difference,
            differenceString,
            isIncrease: priceQuoteTotal > predictedTotal,
          }
        : {}),
    };

    context.cartQuote.priceChange = priceChange;

    return context;
  }
);
