import axios from "axios";
import { config } from "../../config";

import {
  ShopRoomsRequest,
  ShopRoomsResponse,
  VIEWED_PACKAGE_HOTEL_SHOP_LOADING,
} from "redmond";
import { roomsShopApiPrefix } from "../paths";
import dayjs from "dayjs";
import { trackEvent } from "../trackEvent";

export const fetchPackageHotelShop = (
  body: ShopRoomsRequest
): Promise<ShopRoomsResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const startTime = dayjs();
      const res = await axios.post(roomsShopApiPrefix, body, {
        baseURL: config.baseURL,
      });

      trackEvent({
        eventName: VIEWED_PACKAGE_HOTEL_SHOP_LOADING,
        properties: { runtime: dayjs().diff(startTime, "seconds", true) },
      });

      resolve(res.data.value);
    } catch (e) {
      reject(e);
    }
  });
