export const UPDATE_TRAVELERS_CTA_TEXT = "Update Travelers";
export const SEARCH_AGAIN_CTA_TEXT = "Search Again";
export const CONTINUE_CTA_TEXT = "Continue";
export const SELECT_ANOTHER_PACKAGE_CTA_TEXT = "Select another package";
export const TRY_AGAIN_CTA_TEXT = "Try again";

export const GENERIC_ERROR_TITLE = "There was an error.";
export const INVALID_PASSENGERS_TITLE = "Invalid Passengers";
export const PASSENGER_VALIDATION_TITLE = "Passenger Validation Error";
export const UNABLE_TO_COMPLETE_BOOKING_TITLE =
  "Unable to complete your booking";
export const NO_AVAILABILITY_TITLE = "No Availability";
export const MISSING_CONTACT_INFO_TITLE = "Missing Contact Information";
export const TOO_MANY_PASSENGERS_TITLE = "Too Many Passengers";
export const UPGRADE_REQUIRED_TITLE = "Upgrade is required";
export const BOOKING_FAILED_TITLE = "We were unable to process your booking.";

export const GENERIC_ERROR_SUBTITLE =
  "There was an error, reload the page or select another package.";
export const GENERIC_ERROR_TRY_AGAIN_SUBTITLE =
  "There was an error, please try again or reload the page.";

// flights
export const MIN_AGE_NOT_MET_SUBTITLE =
  "Minors must be accompanied by at least one adult";
export const EXPIRED_PASSPORT_SUBTITLE =
  "Your passport has an expired expiration date. Ensure all information matches your Travel ID and is up to date.";
export const INFANT_KTN_SUBTITLE =
  "It looks like you've added a known traveler number for a lap infant, but lap infants cannot have known traveler numbers.";
export const INFANT_REDRESS_SUBTITLE =
  "It looks like you've added a redress number for a lap infant, but lap infants cannot have redress numbers.";
export const INVALID_PASSENGERS_SUBTITLE =
  "It looks like your information is incorrect. Please go back and confirm all of your passenger information is correct.";
export const INVALID_PASSPORT_NUMBER_SUBTITLE =
  "Your passport number is not valid. Ensure all information matches your Travel ID and is up to date";
export const LAP_INFANT_TOO_OLD_SUBTITLE =
  "This passenger is too old to sit on a lap. Please go back and select a seated infant or child.";
export const LAP_INFANTS_UNSUPPORTED_SUBTITLE =
  "Lap infants are not supported on this flight. Please go back and select a seated infant to continue with the purchase.";
export const MALFORMED_KTN_SUBTITLE =
  "Your known traveler number format is incorrect. Please ensure that your information matches your correct known traveler number.";
export const MALFORMED_REDRESS_SUBTITLE =
  "Your redress number format is incorrect. Please ensure that your information matches your correct known traveler number.";
export const MISSING_AIRLINE_LOCATOR_SUBTITLE =
  "We tried to complete your booking, but encountered a technical error on our end. Please try making the booking again. If the issue keeps happening, contact customer support.";
export const MISSING_NATIONALITY_SUBTITLE =
  "It looks like we're missing your nationality. Please go back and add your country of citizenship in your passenger information.";
export const MISSING_PASSPORT_SUBTITLE =
  "Enter Passport details for all passengers. Ensure all information matches your Travel ID.";
export const NO_AVAILABILITY_SUBTITLE =
  "Unfortunately, we can no longer find any availability for this flight(s). Please select a new flight or try again.";
export const NO_CONTACT_INFO_SUBTITLE =
  "We're missing your contact information in case we need to notify you about changes to your booking. Please fill out your phone number and your email address.";
export const TECHNICAL_ERROR_SUBTITLE =
  "We tried to complete your booking, but encountered a technical error on our end. Please try making the booking again. If the issue keeps happening, contact customer support.";
export const SEATED_INFANTS_UNSUPPORTED_SUBTITLE =
  "Seated infants are not supported on this flight. Please go back and select a lap infant to continue with the purchase.";
export const TOO_MANY_LAP_INFANTS_SUBTITLE =
  "There are too many lap infants. Please make sure you have an adult passenger for every lap infant.";
export const TOO_MANY_PASSENGERS_SUBTITLE =
  "Oops! The airline only allows you to book 6 passengers in a single reservation. Please go back and make separate reservations if you have more than 6 passengers.";

// hotels
export const LACK_OF_INVENTORY_TITLE =
  "Oh no - this hotel price is no longer available";
export const LACK_OF_INVENTORY_SUBTITLE =
  "Prices can change frequently: please try again in a few hours or try another hotel.";
export const RATE_NOT_AVAILABLE_TITLE =
  "Oh no - this hotel rate is no longer available";
export const RATE_NOT_AVAILABLE_SUBTITLE =
  "Please select another hotel rate to continue your stay.";
export const CHECK_IN_AGE_NOT_MET_TITLE = "Check-In Minimum Age Not Met";
export const CHECK_IN_AGE_NOT_MET_SUBTITLE =
  "The selected traveler does not meet the hotel’s minimum age requirements for check-in.";

// general
export const PRICE_INCREASE_TITLE = (difference: number) =>
  `The price per traveler for this package has increased by $${difference.toFixed()}.`;
export const PRICE_DECREASE_TITLE = (difference: number) =>
  `You’re in luck! The price per traveler for this package has decreased by $${difference.toFixed()}.`;
export const PRICE_CHANGE_SUBTITLE =
  "Sometimes package prices can change unexpectedly. You can review the new package price per traveler in the price total.";
