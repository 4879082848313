import { CallState, FinalizePackageResponse } from "redmond";
import { actions, actionTypes } from "../actions";

export interface IPackageItineraryReviewState {
  finalizePackageResponse?: FinalizePackageResponse;
  finalizePackageCallState: CallState;
}

export const initalState: IPackageItineraryReviewState = {
  finalizePackageResponse: undefined,
  finalizePackageCallState: CallState.NotCalled,
};

export const reducer = (
  state: IPackageItineraryReviewState = initalState,
  action: actions.PackageItineraryReviewActions
): IPackageItineraryReviewState => {
  switch (action.type) {
    case actionTypes.FINALIZE_PACKAGE:
      return {
        ...state,
        finalizePackageCallState: CallState.InProcess,
      };
    case actionTypes.SET_FINALIZE_PACKAGE_RESPONSE: {
      return {
        ...state,
        finalizePackageResponse: action.finalizeResponse,
        finalizePackageCallState: CallState.Success,
      };
    }
    case actionTypes.SET_FINALIZE_PACKAGE_RESPONSE_CALL_STATE_FAILED:
      return {
        ...state,
        finalizePackageCallState: CallState.Failed,
      };

    default:
      return { ...state };
  }
};
