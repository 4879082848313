import {
  FareclassOptionFilter,
  FareclassShelfBrandName,
  FlightRatingsEnum,
  TripDetails,
} from "redmond";
import { min } from "lodash-es";

export function getSelectedFareClasses(
  fareClassOptionFilter: FareclassOptionFilter
): ReadonlyArray<FareclassShelfBrandName> {
  return fareClassOptionFilter
    ? Object.entries(fareClassOptionFilter)
        .filter(([, isSelected]) => isSelected)
        .map(([key]) => key as FareclassShelfBrandName)
    : [];
}

export function getSelectedFareClass(
  fareClassOptionFilter: FareclassOptionFilter
): FareclassShelfBrandName | undefined {
  const selectedFareClasses = getSelectedFareClasses(fareClassOptionFilter);

  return selectedFareClasses.length > 0 ? selectedFareClasses[0] : undefined;
}

export const getLowestAvailableFareByRating = (
  tripDetails: TripDetails
): FareclassShelfBrandName => {
  const availableFareRatings = tripDetails.fareDetails.flatMap((fareDetail) =>
    fareDetail.slices.map((slice) => slice.fareShelf?.rating)
  );

  const lowestFareRating = min(availableFareRatings);

  const lowestFareBrand = Object.keys(FlightRatingsEnum).find(
    (fareBrandKey) =>
      FlightRatingsEnum[fareBrandKey as FareclassShelfBrandName] ===
      lowestFareRating
  ) as FareclassShelfBrandName;

  return lowestFareBrand || "basic";
};
