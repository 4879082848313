import { Box } from "@material-ui/core";
import React from "react";
import { SelectedPackagePricingCardConnectorProps } from "./container";
import { PackageHotelPriceAndRewardsDisplay } from "halifax";
import "./styles.scss";

interface ISelectedPackagePricingCard
  extends SelectedPackagePricingCardConnectorProps {
  isMobile?: boolean;
}

export const SelectedPackagePricingCard = (
  props: ISelectedPackagePricingCard
) => {
  const {
    selectedPackageByLodging,
    rewardsKey,
    nightCount,
    travelersCount,
    selectedPackageByFlight,
    isMobile,
  } = props;
  if (!selectedPackageByLodging) return null;
  const { packageDetails } = selectedPackageByLodging;
  return (
    <Box className="selected-package-pricing-card-root">
      <PackageHotelPriceAndRewardsDisplay
        pricing={selectedPackageByFlight?.pricing ?? packageDetails?.pricing} // Outbound/the default uses selected package pricing from hotel shop. Return uses the pricing from outbound flight selected
        nightCount={nightCount ?? null}
        travelersCount={travelersCount}
        rewardsKey={rewardsKey}
        isMobile={isMobile}
      />
    </Box>
  );
};
