import {
  Box,
  Button,
  Divider,
  Typography,
  useScrollTrigger,
} from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { PackageItineraryReviewConnectorProps } from "./container";
import {
  ActionButton,
  ActionLink,
  Header,
  Icon,
  IconName,
  PackageHotelPriceAndRewardsDisplay,
  useDeviceTypes,
} from "halifax";
import "./styles.scss";
import { PackagesShopProgressBar } from "../book/components";
import { PATH_HOME } from "../../utils/paths";
import { RouteComponentProps } from "react-router-dom";
import { ReviewHotelItinerary } from "./components/ReviewHotelItinerary";
import { ReviewFlightItinerary } from "./components/ReviewFlightItinerary";
import clsx from "clsx";
import * as constants from "./constants";
import { ChangeItineraryModal } from "./components/ChangeItineraryModal";
import dayjs from "dayjs";
import { ClientContext } from "../../App";
import { RewardsAccountSelection } from "../rewards/components";
import { TravelWalletDrawer } from "../travel-wallet/components";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CallState, FlightShopStep } from "redmond";
import {
  goToBook,
  goToHotelShop,
} from "../hotel-shop/utils/queryStringHelpers";
import { ANCILLARY_DISCLAIMER_TEXT } from "../search/components/PackagesInfoModal/textConstants";
import { PackageDiscountBanner } from "../book/components/PackageDiscountBanner";

interface IPackageItineraryReviewProps
  extends PackageItineraryReviewConnectorProps,
    RouteComponentProps {}

export const PackageItineraryReview = ({
  finalizePackage,
  selectedPackageByLodging,
  rewardsKey,
  nightCount,
  travelersCount,
  selectedPackageByFlight,
  history,
  departureDate,
  returnDate,
  setPackagesFlightShopProgress,
  finalizePackageCallState,
}: IPackageItineraryReviewProps) => {
  const { matchesMobile } = useDeviceTypes();
  const [openChangeRoomModal, setOpenChangeRoomModal] = useState(false);
  const [openChangeHotelModal, setOpenChangeHotelModal] = useState(false);
  const [openChangeOutboundFlightModal, setOpenChangeOutboundFlightModal] =
    useState(false);
  const clientContext = useContext(ClientContext);
  const { logo } = clientContext;
  const scrollTrigger = useScrollTrigger({ disableHysteresis: true });

  const packageFinalizeNotComplete = [
    CallState.NotCalled,
    CallState.InProcess,
  ].includes(finalizePackageCallState);

  useEffect(() => {
    finalizePackage();
  }, []);

  useEffect(() => {
    if (!selectedPackageByLodging) {
      goToHotelShop({ history });
    }
  }, [selectedPackageByLodging]);

  if (!selectedPackageByLodging) return null;

  const { packageDetails } = selectedPackageByLodging;

  return (
    <Box
      className={clsx("package-itinerary-review-root", {
        mobile: matchesMobile,
      })}
    >
      {matchesMobile ? (
        <Box
          className={clsx("mobile-review-pkg-header-container", {
            scrolled: scrollTrigger,
          })}
        >
          <Box className="go-back-button-section">
            <ActionLink
              className={clsx("go-back-button")}
              onClick={() => {
                setPackagesFlightShopProgress(FlightShopStep.ChooseReturn);
                history.goBack();
              }}
              content={<FontAwesomeIcon icon={faChevronLeft} />}
            />
          </Box>
          <Box
            className={clsx(
              "locations-and-dates-section",
              "header-middle-section"
            )}
          >
            <>
              <Typography variant="body1" className="locations-section">
                {constants.REVIEW_PACKAGE_DETAILS}
              </Typography>
              <Box className="dates-section">
                {departureDate && returnDate && (
                  <Typography variant={"body2"}>
                    {dayjs(departureDate).format("ddd, MMM DD")} -{" "}
                    {dayjs(returnDate).format("ddd, MMM DD")}
                  </Typography>
                )}
              </Box>
            </>
          </Box>
        </Box>
      ) : (
        <Header
          className="rewards-components-section"
          left={
            <Box className={"rewards-account-section-left-content"}>
              <Box className={"logo"} onClick={() => history.push(PATH_HOME)}>
                {logo}
              </Box>

              <Box className={"rewards-account-section-travel-details"}>
                <Typography variant={"body1"} tabIndex={0}>
                  {constants.REVIEW_HOTEL_AND_FLIGHT}
                </Typography>
                {departureDate && returnDate && (
                  <Typography variant={"body2"}>
                    {dayjs(departureDate).format("ddd, MMM DD")} -{" "}
                    {dayjs(returnDate).format("ddd, MMM DD")}
                  </Typography>
                )}
              </Box>
            </Box>
          }
          right={
            <Box className="desktop-flight-list-rewards-account-contents">
              <RewardsAccountSelection
                className={clsx("b2b hide-balance-border")}
                popoverClassName="b2b"
              />
              <TravelWalletDrawer />
            </Box>
          }
        ></Header>
      )}
      <PackagesShopProgressBar isMobile={matchesMobile} />
      {matchesMobile && (
        <>
          <Box className="mobile-rewards-account-contents">
            <RewardsAccountSelection className="b2b" popoverClassName="b2b" />
          </Box>
          <Box className="pricing-details-wrapper">
            <PackageHotelPriceAndRewardsDisplay
              pricing={
                selectedPackageByFlight?.pricing ?? packageDetails?.pricing
              }
              nightCount={nightCount ?? null}
              travelersCount={travelersCount}
              rewardsKey={rewardsKey}
            />
          </Box>
          <PackageDiscountBanner
            discount={selectedPackageByFlight?.pricing.totalPackageSavings.fiat}
          />
          <Divider />
        </>
      )}
      <Box className="hotel-itinerary-header">
        <Icon name={IconName.HotelFunnelIcon} />
        <Typography className="header-text">
          {constants.REVIEW_HOTEL}
        </Typography>
      </Box>
      <Box className={clsx("package-itinerary-review-wrapper")}>
        <Box className="left-section">
          <ReviewHotelItinerary
            setOpenChangeHotelModal={setOpenChangeHotelModal}
            setOpenChangeRoomModal={setOpenChangeRoomModal}
            showCardContentIcons={!matchesMobile}
          />
          <Divider />
          <Box className="flight-itinerary-header">
            <Icon name={IconName.FlightFunnelIcon} />
            <Typography className="header-text">
              {constants.REVIEW_FLIGHT}
            </Typography>
          </Box>
          <ReviewFlightItinerary
            setOpenChangeOutboundFlightModal={setOpenChangeOutboundFlightModal}
            showChangeCTAs
          />

          <Divider className="ancillary-disclaimer-divider" />

          <Typography className="ancillary-disclaimer-text">
            {ANCILLARY_DISCLAIMER_TEXT}
          </Typography>
        </Box>
        {matchesMobile ? (
          <Box className="continue-button-section">
            <ActionButton
              className="continue-button b2b"
              onClick={() => goToBook({ history })}
              message={
                packageFinalizeNotComplete
                  ? constants.CONTINUE_TO_CHECKOUT_LOADING
                  : constants.CONTINUE_TO_CHECKOUT
              }
              disabled={packageFinalizeNotComplete}
            />
          </Box>
        ) : (
          <Box className="right-section">
            <Box className="right-section-sticky-content">
              <Box className="review-itinerary-card-content-container pricing-details">
                <PackageHotelPriceAndRewardsDisplay
                  pricing={
                    selectedPackageByFlight?.pricing ?? packageDetails?.pricing
                  }
                  nightCount={nightCount ?? null}
                  travelersCount={travelersCount}
                  rewardsKey={rewardsKey}
                />
                <Button
                  className="continue-button"
                  onClick={() => goToBook({ history })}
                  disabled={packageFinalizeNotComplete}
                >
                  {packageFinalizeNotComplete
                    ? constants.CONTINUE_TO_CHECKOUT_LOADING
                    : constants.CONTINUE_TO_CHECKOUT}
                </Button>
              </Box>

              <PackageDiscountBanner
                discount={
                  selectedPackageByFlight?.pricing.totalPackageSavings.fiat
                }
              />
            </Box>
          </Box>
        )}
      </Box>

      {(openChangeHotelModal ||
        openChangeRoomModal ||
        openChangeOutboundFlightModal) && (
        <ChangeItineraryModal
          type={
            openChangeHotelModal
              ? "hotel"
              : openChangeRoomModal
              ? "room"
              : "outbound-flight"
          }
          openModal={
            openChangeHotelModal ||
            openChangeRoomModal ||
            openChangeOutboundFlightModal
          }
          setOpenModal={
            openChangeHotelModal
              ? setOpenChangeHotelModal
              : openChangeRoomModal
              ? setOpenChangeRoomModal
              : setOpenChangeOutboundFlightModal
          }
          isMobile={matchesMobile}
        />
      )}
    </Box>
  );
};
