import React from "react";
import "./styles.scss";
import {
  BannerSeverity,
  getCurrencySymbol,
  Icon,
  IconName,
  NotificationBanner,
} from "halifax";
import { PACKAGE_BANNER_EARN_TEXT } from "./textConstants";
import {
  CardPaymentSelectors,
  RewardsPaymentSelectors,
  useCheckoutStateSelector,
} from "@capone/checkout";
import {
  getPackagesShopPricing,
  getQuotedPackageDiscount,
} from "../../state/selectors/common";
import { FiatPrice, RewardsAccount, RewardsPrice } from "redmond";

export interface IPackageDiscountBannerProps {
  discount?: FiatPrice;
  earn?: RewardsPrice;
  earnRewardsAccount?: RewardsAccount;
}

export const PackageDiscountBanner = ({
  discount,
  earn,
  earnRewardsAccount,
}: IPackageDiscountBannerProps) => {
  if (!discount?.value && !earn?.value) return null;

  return (
    <NotificationBanner
      icon={<Icon name={IconName.PiggyBankCircleOutline} />}
      className="package-discount-banner"
      content={PACKAGE_BANNER_EARN_TEXT(discount, earn, earnRewardsAccount)}
      severity={BannerSeverity.IMPROVEMENT}
    />
  );
};

export const ConnectedPackageDiscountBanner = () => {
  const quotedPackageDiscount = useCheckoutStateSelector(
    getQuotedPackageDiscount
  );
  const packagesShopPricing = useCheckoutStateSelector(getPackagesShopPricing);
  const packageShopDiscount = packagesShopPricing?.totalPackageSavings.fiat;

  const earnForSelectedCard = useCheckoutStateSelector(
    RewardsPaymentSelectors.getEarnForSelectedRewardsAccount
  );
  const earnForHighestEarnAccount = useCheckoutStateSelector(
    RewardsPaymentSelectors.getEarnForHighestEarnRewardsAccount
  );
  const selectedCardPaymentRewardsAccountId = useCheckoutStateSelector(
    CardPaymentSelectors.getSelectedPaymentMethodRewardsAccountId
  );
  const highestEarnRewardsAccount = useCheckoutStateSelector(
    RewardsPaymentSelectors.getHighestEarnAccount
  );

  const rewardsAccountToUse = useCheckoutStateSelector(
    RewardsPaymentSelectors.getRewardsAccounts
  )?.find(
    (account) =>
      account.accountReferenceId ===
      (selectedCardPaymentRewardsAccountId ||
        highestEarnRewardsAccount?.accountReferenceId)
  );

  const earnToUse = earnForSelectedCard || earnForHighestEarnAccount;

  const discount = quotedPackageDiscount
    ? {
        value: quotedPackageDiscount.amount.amount,
        currencyCode: quotedPackageDiscount.amount.currency,
        currencySymbol: getCurrencySymbol(
          quotedPackageDiscount.amount.currency
        ),
      }
    : packageShopDiscount;

  return (
    <PackageDiscountBanner
      discount={discount}
      earn={earnToUse}
      earnRewardsAccount={rewardsAccountToUse}
    />
  );
};
