import {
  GenericInfoPopup,
  Icon,
  IconName,
  getAgentErrorSubtitle,
  getAgentErrorTitle,
  useDeviceTypes,
} from "halifax";
import React, { useContext, useState } from "react";
import { RouteComponentProps } from "react-router";
import {
  ErrorType,
  MODAL_ALERT,
  ModalAlertProperties,
  ModalButtonType,
  ModalCategoryType,
  ModalScreens,
} from "redmond";

import { ClientContext } from "../../../../App";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";
import { PATH_HOME, PATH_HOTELS_AVAILABILITY } from "../../../../utils/paths";
import { AvailabilityErrorModalConnectorProps } from "./container";
import "./styles.scss";
import * as textConstants from "./textConstants";
import { getErrorModalProps } from "./utils";

import { initialFilterOptions } from "../../../search/reducer";

export interface IAvailabilityErrorModalProps
  extends AvailabilityErrorModalConnectorProps,
    RouteComponentProps {}

export const AvailabilityErrorModal = (props: IAvailabilityErrorModalProps) => {
  const {
    hasFailed,
    history,
    fetchInitialPackagesAvailability,
    errors,
    hasSetStopsOption,
    hasSetFareClass,
  } = props;
  const { isAgentPortal } = useContext(ClientContext);
  const { matchesMobile } = useDeviceTypes();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleGoBack = () => {
    setIsOpen(false);
    trackEvent({
      eventName: MODAL_ALERT,
      properties: {
        ...modalEventProperties,
        button_choice: ModalButtonType.SECONDARY,
      },
    });

    history.push(PATH_HOME);
  };

  const handleSearchAgain = () => {
    setIsOpen(false);
    trackEvent({
      eventName: MODAL_ALERT,
      properties: {
        ...modalEventProperties,
        button_choice: ModalButtonType.PRIMARY,
      },
    });

    fetchInitialPackagesAvailability(history);
  };

  const handleResetFlightFilters = () => {
    setIsOpen(false);

    const currentParams = new URLSearchParams(history.location.search);

    currentParams.set("stopsOption", initialFilterOptions.stopsOption);

    if (hasSetFareClass) {
      currentParams.delete("fareClass");
    }

    history.replace(`${PATH_HOTELS_AVAILABILITY}?${currentParams.toString()}`);
  };

  const hasSetFilters = hasSetStopsOption || hasSetFareClass;

  const errorModalProps = getErrorModalProps(
    errors,
    hasSetFilters,
    handleGoBack,
    handleSearchAgain,
    handleResetFlightFilters
  );

  const modalEventProperties: ModalAlertProperties = {
    type: "packages_hotels_availabiltity_error",
    primary_button: textConstants.SEARCH_AGAIN_BUTTON,
    secondary_button: textConstants.GO_BACK_BUTTON,
    screen: ModalScreens.PACKAGES_AVAILABILITY,
    category: ModalCategoryType.TROUBLE,
    modal_subtitle: errorModalProps.subtitle?.toString() || "",
    modal_title: errorModalProps.title?.toString() || "",
    agent_title: getAgentErrorTitle(ErrorType.NoAvailability),
    agent_subtitle: getAgentErrorSubtitle(ErrorType.NoAvailability),
    funnel: "packages",
    step: "availability",
  };

  React.useEffect(() => {
    if (hasFailed) {
      setIsOpen(true);
      trackEvent({
        eventName: MODAL_ALERT,
        properties: { ...modalEventProperties },
      });
    }
  }, [hasFailed]);

  return (
    <GenericInfoPopup
      agentSubtitle={getAgentErrorSubtitle(ErrorType.NoAvailability)}
      agentTitle={getAgentErrorTitle(ErrorType.NoAvailability)}
      className="packages-availability-error-modal-root"
      image={<Icon className="error-icon" name={IconName.ErrorState} />}
      isAgent={isAgentPortal}
      isMobile={matchesMobile}
      open={isOpen}
      {...errorModalProps}
    />
  );
};
