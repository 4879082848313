import { PackagesFlightShopStep } from "redmond";

export const SEARCHING_FOR_FLIGHTS = "for flights";

export const getShortLabel = (label: string) => {
  return label.split(",")[0].replace(/ \(\d Airports\)/, "");
};

export const CHOOSING_FLIGHT_TEXT = (
  currentStep: PackagesFlightShopStep,
  origin: string,
  destination: string
) => {
  const isOutgoing = currentStep === PackagesFlightShopStep.ChooseDeparture;

  return `Choose ${isOutgoing ? "outbound" : "return"} flight to ${
    isOutgoing ? getShortLabel(destination) : getShortLabel(origin)
  }`;
};

export const SHOW_MORE_NUM = 5;
export const INITIAL_RESULT_SET_SIZE = 10;

export const getStopsString = (stops: number) =>
  stops === 0 ? "Nonstop" : stops > 1 ? `${stops} stops` : "1 stop";

export const getEarnTagText = (earnRate: string | number) =>
  `<span class="font-bold">Earn ${earnRate}X</span> miles on flights`;

export const RECOMMENDED_FLIGHT_LIST_SEPARATOR_PRIMARY_TEXT =
  "Below are all remaining flights available for this route.";

export const RECOMMENDED_FLIGHT_LIST_SEPARATOR_SECONDARY_TEXT =
  "Total price will vary based on final flight selection.";

export const RECOMMENDED_FLIGHT_BANNER_TEXT =
  "Package price includes this recommended flight";

export const REWARDS_BANNER_TEXT = "Prices are round-trip, per traveler";

export const LOADING_FLIGHT_DETAILS_STRING = "Loading Flight Details";
