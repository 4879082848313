import React from "react";
import { RouteComponentProps } from "react-router";
import { Icon, IconName } from "halifax";
import { MobileTripReviewConnectorProps } from "./container";
import "./styles.scss";
import { Box, Typography } from "@material-ui/core";
import { ReviewFlightItinerary } from "../../../itinerary-review/components/ReviewFlightItinerary";
import { ReviewHotelItinerary } from "../../../itinerary-review/components/ReviewHotelItinerary";
import {
  FLIGHT_DETAILS_HEADING_TEXT,
  HOTEL_DETAILS_HEADING_TEXT,
  TRIP_REVIEW_HEADING_TEXT,
  TRIP_REVIEW_SUBTITLE_TEXT,
} from "./textConstants";
import { FlightBookPassengerSelection } from "../FlightBookPassengerSelection";
import { PackagesContactInfo } from "../ContactInfo";
import { PackagesPriceBreakdown } from "../PriceBreakdown";
import { ConnectedPackageDiscountBanner } from "../PackageDiscountBanner";

export interface IMobileTripReviewProps
  extends MobileTripReviewConnectorProps,
    RouteComponentProps {}

export const MobileTripReview = ({}: IMobileTripReviewProps) => {
  return (
    <Box className="packages-checkout-trip-review">
      <Typography className="trip-review-heading" variant="h2">
        {TRIP_REVIEW_HEADING_TEXT}
      </Typography>
      <Typography className="trip-review-subtitle">
        {TRIP_REVIEW_SUBTITLE_TEXT}
      </Typography>

      <ConnectedPackageDiscountBanner />

      <Box className="hotel-itinerary-header">
        <Icon name={IconName.HotelFunnelIcon} />
        <Typography className="header-text" variant="h3">
          {HOTEL_DETAILS_HEADING_TEXT}
        </Typography>
      </Box>

      <ReviewHotelItinerary showCardContentIcons={false} />

      <Box className="flight-itinerary-header">
        <Icon name={IconName.FlightFunnelIcon} />
        <Typography className="header-text" variant="h3">
          {FLIGHT_DETAILS_HEADING_TEXT}
        </Typography>
      </Box>
      <ReviewFlightItinerary />

      <FlightBookPassengerSelection isMobile />
      <PackagesContactInfo isMobile />
      <PackagesPriceBreakdown isMobile />
    </Box>
  );
};
