import {
  AirChfarActions,
  AirDisruptionActions,
  AirPFExerciseActions,
  AirPriceDropActions,
  CardPaymentActions,
  CartActions,
  CartQuoteActions,
  CfarActions,
  ContactInformationActions,
  FlightActions,
  FlightPassengerActions,
  PassportActions,
  ReviewActions,
  RewardsPaymentActions,
  SeatActions,
  WalletActions,
  getObjectKeysAsObject,
} from "@capone/checkout";
// import { actions as CBASeatActions } from "../childStateMachine/seats/actions";
import {
  checkForPriceChange,
  initializeCheckoutState,
  setPaymentFulfillParams,
  setPlatform,
} from "./common";
import { LodgingActions } from "./lodging";

export const Actions: any = {
  ...FlightActions,
  ...CartActions,
  ...AirDisruptionActions,
  ...CfarActions,
  ...ContactInformationActions,
  ...FlightPassengerActions,
  ...PassportActions,
  ...AirPFExerciseActions,
  ...ReviewActions,
  ...SeatActions,
  ...AirChfarActions,
  ...CardPaymentActions,
  ...WalletActions,
  ...RewardsPaymentActions,
  ...AirPriceDropActions,
  ...WalletActions,
  ...LodgingActions,
  ...CartQuoteActions,

  // package common actions
  setPaymentFulfillParams,
  initializeCheckoutState,
  setPlatform,
  checkForPriceChange,
  // put this after shared seat actions to override it
  // ...CBASeatActions,
};

export const ActionTypes = getObjectKeysAsObject(Actions);
