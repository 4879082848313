import {
  ActionButton,
  ActionLink,
  CloseButtonIcon,
  Icon,
  IconName,
} from "halifax";
import React from "react";
import clsx from "clsx";
import { Box, Dialog, Typography } from "@material-ui/core";
import * as constants from "./constants";
import "./styles.scss";
import { ChangeItineraryModalConnectorProps } from "./container";
import { RouteComponentProps } from "react-router-dom";
import {
  PATH_FLIGHT_SHOP,
  PATH_HOTEL_SHOP,
  PATH_HOTELS_AVAILABILITY,
} from "../../../../utils/paths";
import { transformToStringifiedQuery } from "../../../hotel-shop/utils/queryStringHelpers";
import { FlightShopStep, IIdLodgings } from "redmond";
import { transformToStringifiedAvailabilityQuery } from "../../../availability/utils/queryStringHelpers";

interface IChangeItineraryModalProps
  extends ChangeItineraryModalConnectorProps,
    RouteComponentProps {
  type: "hotel" | "room" | "outbound-flight";
  openModal: boolean;
  setOpenModal: (arg: boolean) => void;
  isMobile?: boolean;
}
export const ChangeItineraryModal = (props: IChangeItineraryModalProps) => {
  const {
    type,
    openModal,
    setOpenModal,
    history,
    origin,
    searchedLocation,
    packageHotelQueryParams,
    selectedLodging,
    returnDate,
    departureDate,
    travelers,
    stopsOptions,
    setPackagesFlightShopProgress,
    isMobile,
    fareClassOptionFilter,
    resetPackagesAvailabilityCallState,
  } = props;

  const renderModalContentInfo = (
    type: "hotel" | "room" | "outbound-flight"
  ) => {
    let params: string | undefined;
    switch (type) {
      case "hotel":
        return {
          icon: IconName.HotelFunnelIcon,
          body: constants.CHANGE_HOTEL_BODY,
          continueOnClick: () => {
            if (origin && departureDate && returnDate) {
              resetPackagesAvailabilityCallState();
              history.push(
                `${PATH_HOTELS_AVAILABILITY}${transformToStringifiedAvailabilityQuery(
                  origin?.id?.code?.code,
                  (searchedLocation?.id as IIdLodgings).lodgingSelection
                    .searchTerm,
                  departureDate,
                  returnDate,
                  travelers.adultsCount,
                  travelers.children,
                  travelers.infants,
                  stopsOptions,
                  fareClassOptionFilter
                )}`
              );
            }
          },
        };

      case "room":
        if (selectedLodging && origin) {
          params = transformToStringifiedQuery({
            lodgingId: selectedLodging?.lodging.id,
            origin: origin?.id?.code?.code,
            destination: (searchedLocation?.id as IIdLodgings).lodgingSelection
              .searchTerm,
            ...packageHotelQueryParams,
          });
        }
        return {
          icon: IconName.LateCheckoutIcon,
          body: constants.CHANGE_ROOM_BODY,
          continueOnClick: () => {
            if (params) {
              history.push(`${PATH_HOTEL_SHOP}${params}`, {
                fromPage: location.pathname,
              });
            }
          },
        };
      case "outbound-flight":
        if (selectedLodging && origin) {
          params = transformToStringifiedQuery({
            lodgingId: selectedLodging?.lodging.id,
            origin: origin?.id?.code?.code,
            destination: searchedLocation?.label ?? "",
            ...packageHotelQueryParams,
          });
        }
        return {
          icon: IconName.FlightFunnelIcon,
          body: constants.CHANGE_FLIGHT_BODY,
          continueOnClick: () => {
            setPackagesFlightShopProgress(FlightShopStep.ChooseDeparture);
            history.push(`${PATH_FLIGHT_SHOP}${params}`, {
              fromPage: location.pathname,
            });
          },
        };
    }
  };

  const renderModalContent = () => {
    const { icon, body, continueOnClick } = renderModalContentInfo(type);
    return (
      <>
        <Box className="top-button-right">
          <ActionLink
            className="modal-close-button"
            content={<CloseButtonIcon />}
            label="Close"
            onClick={() => setOpenModal(false)}
          />
        </Box>
        <Box className="modal-content">
          <Box className="icon-wrapper">
            <Icon name={icon} />
          </Box>
          <Typography className="modal-text">{body}</Typography>
          <Box className="cta-wrappers">
            <ActionButton
              onClick={continueOnClick}
              message={constants.getContinueCTAText(type)}
              className="b2b continue-cta"
              defaultStyle="h4r-primary"
            />
            <ActionButton
              onClick={() => setOpenModal(false)}
              message={isMobile ? constants.CANCEL : constants.CLOSE}
              defaultStyle="h4r-secondary"
            />
          </Box>
        </Box>
      </>
    );
  };
  return (
    <Dialog
      open={openModal}
      onClose={() => {
        setOpenModal(false);
      }}
      className={clsx("change-itinerary-modal-root", { mobile: isMobile })}
      /** TransitionProps fixes `role` issue bug in MUIv4 - https://github.com/mui/material-ui/issues/18935  */
      TransitionProps={{ role: "none" } as never}
      PaperProps={{
        /* eslint-disable */
        // @ts-ignore: Fix the type definition of PaperProps to include tabIndex.
        tabIndex: 0,
        /* eslint-enable */
      }}
      disableEnforceFocus
    >
      {renderModalContent()}
    </Dialog>
  );
};
