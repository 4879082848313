import {
  ContactSelectors,
  FlightPassengerEventTypes,
  FlightPassengerSelectors,
  getParentState,
  LodgingSelectors,
  ParentState,
  PassengerErrorModalTypes,
  useCheckoutState,
  useCheckoutStateSelector,
  useChildMachineSelector,
  useChildMachineState,
} from "@capone/checkout";
import {
  GenericInfoPopup,
  Icon,
  IconName,
  useDeviceTypes,
  usePrevious,
} from "halifax";
import React from "react";
import { Event, TEvent } from "../../state/events";
import {
  CONTINUE_CTA_TEXT,
  GENERIC_ERROR_SUBTITLE,
  GENERIC_ERROR_TITLE,
  INVALID_PASSENGER_TITLE,
  LAP_INFANT_TOO_OLD_SUBTITLE,
  LAP_INFANTS_UNSUPPORTED_SUBTITLE,
  MIN_AGE_NOT_MET_SUBTITLE,
  NO_PASSENGERS_SUBTITLE,
  SEARCHED_TRAVELER_COUNT_NOT_MET_SUBTITLE,
  SEARCHED_TRAVELER_COUNT_NOT_MET_TITLE,
  SEATED_INFANTS_UNSUPPORTED_SUBTITLE,
  SELECT_DIFFERENT_ROOM_CTA_TEXT,
  SELECTED_TRAVELERS_EXCEED_ROOM_CAPACITY_SUBTITLE,
  SELECTED_TRAVELERS_EXCEED_ROOM_CAPACITY_TITLE,
  TOO_MANY_LAP_INFANTS_SUBTITLE,
  TOO_MANY_PASSENGERS_SUBTITLE,
  TOO_MANY_PASSENGERS_TITLE,
  UPDATE_TRAVELERS_CTA_TEXT,
} from "./constants";
import { IGenericInfoPopupProps } from "halifax/build/GenericInfoPopup";
import { PackagesMachineContext } from "../../state/types";
import { RouteComponentProps } from "react-router-dom";
import { PassengerErrorModalConnectorProps } from "./container";
import clsx from "clsx";
import { goToHotelShop } from "../../../hotel-shop/utils/queryStringHelpers";
import "./styles.scss";
import {
  MODAL_ALERT,
  ModalAlertProperties,
  ModalCategoryType,
  ModalScreens,
} from "redmond";
import { trackEvent } from "../../../../api/v0/trackEvent";

export interface IPassengerErrorModalProps
  extends RouteComponentProps,
    PassengerErrorModalConnectorProps {}

export const PassengerErrorModal = ({ history }: IPassengerErrorModalProps) => {
  const { matchesMobile } = useDeviceTypes();

  const [state, _send] = useCheckoutState<TEvent, PackagesMachineContext>();

  const parentState = getParentState(state.value) as ParentState;

  const passportModalOpen = parentState === ParentState.passport;

  const [, passengerChildMachineSend] = useChildMachineState<
    TEvent,
    PackagesMachineContext
  >(ParentState.passengerInformation);

  const modalOpen = useChildMachineSelector(
    ParentState.passengerInformation,
    FlightPassengerSelectors.getPassengerErrorOpen
  );
  const passengerError = useChildMachineSelector(
    ParentState.passengerInformation,
    FlightPassengerSelectors.getPassengerError
  );

  const infantOnlyError = useChildMachineSelector(
    ParentState.passengerInformation,
    FlightPassengerSelectors.getInfantOnlyError
  );

  const selectedRoomCapacity =
    useCheckoutStateSelector(LodgingSelectors.getSelectedRoomProduct)?.bedTypes
      .occupancy || 0;

  const passengersValidated = useChildMachineSelector(
    ParentState.passengerInformation,
    FlightPassengerSelectors.getPassengerStateValidated
  );

  const contactInfoValidated = useCheckoutStateSelector(
    ContactSelectors.getContactInformationValidated
  );

  const clearError = () =>
    passengerChildMachineSend(Event.CLEAR_PASSENGER_INFORMATION_ERROR);

  const openPassengerPicker = () => {
    passengerChildMachineSend(Event.OPEN_PASSENGER_PICKER);
    clearError();
  };

  const openPassportForm = () => {
    passengerChildMachineSend(Event.OPEN_PASSPORT_FORM);
  };

  const handlePassengerCountDifferenceContinue = () => {
    clearError();
    passengerChildMachineSend(
      FlightPassengerEventTypes.DISMISS_NUM_PASSENGERS_ALERT
    );
    if (matchesMobile) {
      if (passengersValidated) {
        passengerChildMachineSend(Event.NEXT);
      }
    } else {
      if (passengersValidated && contactInfoValidated) {
        passengerChildMachineSend(Event.NEXT);
      }
    }
  };

  const previousPassengerError = usePrevious(passengerError);

  if (
    passengerError?.type === PassengerErrorModalTypes.PassportValidationFail
  ) {
    if (!passportModalOpen) {
      openPassportForm();
    }

    return null;
  }

  const getErrorModalProps: () => Pick<
    IGenericInfoPopupProps,
    "title" | "subtitle" | "buttons" | "image"
  > = () => {
    const buttons: IGenericInfoPopupProps["buttons"] = [
      {
        buttonText: UPDATE_TRAVELERS_CTA_TEXT,
        onClick: openPassengerPicker,
      },
    ];
    if (infantOnlyError) {
      return {
        title: INVALID_PASSENGER_TITLE,
        subtitle: MIN_AGE_NOT_MET_SUBTITLE,
        buttons,
      };
    }
    // fallback to previous error to prevent default message flashing on modal close
    switch (passengerError?.type || previousPassengerError?.type) {
      case PassengerErrorModalTypes.NoPassengers:
        return {
          title: INVALID_PASSENGER_TITLE,
          subtitle: NO_PASSENGERS_SUBTITLE,
          buttons,
        };

      case PassengerErrorModalTypes.ADTRequired:
        return {
          title: INVALID_PASSENGER_TITLE,
          subtitle: MIN_AGE_NOT_MET_SUBTITLE,
          buttons,
        };
      case PassengerErrorModalTypes.INSNotAllowed:
        return {
          title: INVALID_PASSENGER_TITLE,
          subtitle: SEATED_INFANTS_UNSUPPORTED_SUBTITLE,
          buttons,
        };
      case PassengerErrorModalTypes.LapInfantsUnsupported:
        return {
          title: INVALID_PASSENGER_TITLE,
          subtitle: LAP_INFANTS_UNSUPPORTED_SUBTITLE,
          buttons,
        };
      case PassengerErrorModalTypes.InSufficientADTCount:
      case PassengerErrorModalTypes.UnaccompaniedMinor:
        return {
          title: INVALID_PASSENGER_TITLE,
          subtitle: TOO_MANY_LAP_INFANTS_SUBTITLE,
          buttons,
        };
      case PassengerErrorModalTypes.TooManyPassengers:
        return {
          title: TOO_MANY_PASSENGERS_TITLE,
          subtitle: TOO_MANY_PASSENGERS_SUBTITLE,
          buttons,
        };
      case PassengerErrorModalTypes.InfantIsMinor:
        return {
          title: INVALID_PASSENGER_TITLE,
          subtitle: LAP_INFANT_TOO_OLD_SUBTITLE,
          buttons,
        };
      case PassengerErrorModalTypes.SelectedPassengersExceedRoomCapacity:
        return {
          title:
            SELECTED_TRAVELERS_EXCEED_ROOM_CAPACITY_TITLE(selectedRoomCapacity),
          subtitle: SELECTED_TRAVELERS_EXCEED_ROOM_CAPACITY_SUBTITLE,
          buttons: [
            {
              buttonText: UPDATE_TRAVELERS_CTA_TEXT,
              onClick: openPassengerPicker,
            },
            {
              buttonText: SELECT_DIFFERENT_ROOM_CTA_TEXT,
              onClick: () => goToHotelShop({ history }),
              defaultStyle: "h4r-secondary",
            },
          ],
        };
      case PassengerErrorModalTypes.SearchPassengerNumNotReached:
        return {
          title: SEARCHED_TRAVELER_COUNT_NOT_MET_TITLE,
          subtitle: SEARCHED_TRAVELER_COUNT_NOT_MET_SUBTITLE,
          image: <Icon className="error-icon" name={IconName.UserAdd} />,
          buttons: [
            {
              buttonText: UPDATE_TRAVELERS_CTA_TEXT,
              onClick: openPassengerPicker,
            },
            {
              buttonText: CONTINUE_CTA_TEXT,
              onClick: handlePassengerCountDifferenceContinue,
              defaultStyle: "h4r-secondary",
            },
          ],
        };
      case PassengerErrorModalTypes.TripPricing:
      case PassengerErrorModalTypes.UpdatePassenger:
      case PassengerErrorModalTypes.FetchPassengers:
      case PassengerErrorModalTypes.ValidatePassengers:
      case PassengerErrorModalTypes.DeletePassenger:
      case PassengerErrorModalTypes.ValidateAndTripPricing:
      default:
        return {
          title: GENERIC_ERROR_TITLE,
          subtitle: GENERIC_ERROR_SUBTITLE,
          buttons,
        };
    }
  };

  const errorModalProps = getErrorModalProps();

  const modalEventProperties: ModalAlertProperties = {
    type: passengerError?.type || "",
    // quick workaround to bypass 502 errors from API when it contains spaces

    primary_button:
      errorModalProps.buttons?.[0]?.buttonText?.replaceAll(" ", "_") || "",
    secondary_button: errorModalProps.buttons?.[1]?.buttonText || "",
    screen: ModalScreens.PACKAGES_CHECKOUT,
    category: ModalCategoryType.TROUBLE,
    modal_subtitle: errorModalProps.subtitle as string,
    modal_title: errorModalProps.title as string,
    agent_subtitle: errorModalProps.subtitle as string,
    agent_title: errorModalProps.title as string,
    funnel: "packages",
    step: parentState,
  };

  React.useEffect(() => {
    if (modalOpen)
      trackEvent({
        eventName: MODAL_ALERT,
        properties: {
          ...modalEventProperties,
        },
      });
  }, [modalOpen]);

  return (
    <GenericInfoPopup
      open={modalOpen}
      image={<Icon className="error-icon" name={IconName.ErrorState} />}
      className={clsx("packages-passenger-error-modal", {
        mobile: matchesMobile,
      })}
      zIndex={1301}
      {...errorModalProps}
    />
  );
};
