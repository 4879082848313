import { connect, ConnectedProps } from "react-redux";
import { PassengerErrorModal } from "./component";
import { IStoreState } from "../../../../reducers/types";
import { withRouter } from "react-router-dom";

const mapStateToProps = (_: IStoreState) => {
  return {};
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PassengerErrorModalConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedPassengerErrorModal = withRouter(
  connector(PassengerErrorModal)
);
