import {
  SearchPackageResponseError,
  SearchPackageResponseFailure,
} from "redmond";
import * as textConstants from "./textConstants";
import clsx from "clsx";
import { IGenericInfoPopupProps } from "halifax/build/GenericInfoPopup";

const defaultButtons = (onGoBack: () => void, onSearchAgain: () => void) => [
  {
    buttonText: textConstants.GO_BACK_BUTTON,
    buttonWrapperClassName: clsx(
      "availability-error-modal-button",
      "secondary"
    ),
    onClick: onGoBack,
  },
  {
    buttonText: textConstants.SEARCH_AGAIN_BUTTON,
    buttonWrapperClassName: clsx("availability-error-modal-button", "primary"),
    onClick: onSearchAgain,
  },
];

export const getErrorModalProps = (
  errors: SearchPackageResponseFailure["errors"] | undefined,
  hasFiltersToReset: boolean,
  onGoBack: () => void,
  onSearchAgain: () => void,
  onResetFlightFilters: () => void
): Pick<IGenericInfoPopupProps, "title" | "subtitle" | "buttons" | "image"> => {
  const error = errors?.[0] || undefined;

  switch (error?.code) {
    case SearchPackageResponseError.NoFlightsFound:
      return {
        title: textConstants.NO_FLIGHTS_ERROR_TITLE,
        subtitle: textConstants.NO_FLIGHT_ERROR_SUBTITLE,
        buttons: [
          {
            buttonText: textConstants.GO_BACK_BUTTON,
            buttonWrapperClassName: clsx(
              "availability-error-modal-button",
              hasFiltersToReset ? "secondary" : "primary"
            ),
            onClick: onGoBack,
          },
          ...(hasFiltersToReset
            ? [
                {
                  buttonText: textConstants.RESET_FILTERS_BUTTON,
                  onClick: onResetFlightFilters,
                },
              ]
            : []),
        ],
      };
    default:
      return {
        title: textConstants.AVAILABILITY_ERROR_TITLE,
        subtitle: textConstants.AVAILABILITY_ERROR_SUBTITLE,
        buttons: defaultButtons(onGoBack, onSearchAgain),
      };
  }
};
