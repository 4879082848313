import dayjs from "dayjs";
import { IStoreState } from "../../../../reducers/types";
import { createSelector } from "@reduxjs/toolkit";
import {
  FareclassOptionFilter,
  HotelEntryProperties,
  IIdLodgings,
  IPriceRange,
  IResult,
  ITrackingProperties,
  Lodging,
  LodgingCollectionEnum,
  PackageEntryProperties,
  Place,
  RewardsAccount,
  SearchPackagesResponseInitial,
  SliceStopCountFilter,
  StatementCreditDetail,
  ViewedHotelListProperties,
} from "redmond";
import { initialFilterState } from "../index";
import { isEqual } from "lodash-es";
import {
  PackagesAvailabilityCallState,
  PackagesHotelsAvailabilitySortOption,
} from "../state";
import {
  performAmenitiesFilter,
  performFreeCancelFilter,
  performHotelNameFilter,
  performLoyaltyProgramFilter,
  performMaxPriceFilter,
  performPolicyFilter,
  performStarRatingsFilter,
} from "../utils/processFilters";
import {
  orderByPriceLowToHigh,
  orderByStarRatingHighToLow,
} from "../utils/processSort";
import {
  getDestination,
  getDepartureDate,
  getReturnDate,
  getTravelers,
  getStopsOption,
  getFareclassOptionFilter,
} from "../../../search/reducer";
import { getSelectedFareClass } from "../../../../utils/fareClass";
import {
  getCreditBreakdown,
  getTravelWalletCredit,
} from "../../../travel-wallet/reducer";

export const getPackagesAvailabilityLodgings = (
  state: IStoreState
): Lodging[] =>
  state.packagesAvailability.availabilityResponse?.lodgingAvailability?.value
    .lodgings || [];
export const getPackagesByLodgingId = (state: IStoreState) =>
  state.packagesAvailability.availabilityResponse?.packagesByLodgingId;

export const getPackagesAvailabilityNextPageToken = (state: IStoreState) =>
  state.packagesAvailability.availabilityResponse?.nextPageToken;

export const getPackagesAvailabilityCallState = (state: IStoreState) =>
  state.packagesAvailability.packagesAvailabilityCallState;

export const getPackagesAvailabilityErrors = (state: IStoreState) =>
  state.packagesAvailability.availabilityErrors;

export const getPackageAvailabilitySearchToken = (state: IStoreState) =>
  (
    state.packagesAvailability
      .availabilityResponse as SearchPackagesResponseInitial
  )?.searchToken.value;

export const getHotelAvailabilitySearchLocation = (state: IStoreState) =>
  state.packagesAvailability.searchLocation;

export const getPackagesAvailabilityDepartureDate = (state: IStoreState) =>
  state.packagesAvailability.searchFromDate;

export const getPackagesAvailabilityReturnDate = (state: IStoreState) =>
  state.packagesAvailability.searchUntilDate;

export const getPackageAvailabilityAdultsCount = (state: IStoreState) =>
  state.packagesAvailability.searchAdultsCount;

export const getPackageAvailabilityChildren = (state: IStoreState) =>
  state.packagesAvailability.searchChildren;

export const getPackageAvailabilityInfants = (state: IStoreState) =>
  state.packagesAvailability.searchInfants;

export const getPackagesAvailabilityRoomsCount = (state: IStoreState) =>
  state.packagesAvailability.searchRoomsCount;

export const getPackagesAvailabilityFareclassOptionFilter = (
  state: IStoreState
) => state.packagesAvailability.fareclassOptionFilter;

export const getPackagesAvailabilityStopsOption = (state: IStoreState) =>
  state.packagesAvailability.stopsOption;

export const getSearchedNightCount = createSelector(
  getPackagesAvailabilityDepartureDate,
  getPackagesAvailabilityReturnDate,
  (fromDate, untilDate) => {
    if (!fromDate || !untilDate) {
      return null;
    }
    return dayjs(untilDate).diff(fromDate, "days");
  }
);

export const getMapBound = (state: IStoreState) =>
  state.packagesAvailability.mapBound;

export const getOpenDatesModal = (state: IStoreState) =>
  state.packagesAvailability.openDatesModal;

export const getViewHotelsNearLocation = (state: IStoreState) =>
  state.packagesAvailability.viewHotelsNearLocation;

export const getViewHotelsNearLocationCategories = (state: IStoreState) =>
  state.packagesAvailability.viewHotelsNearLocationCategories;

export const getHotelAvailabilityLodgingIdInFocus = (state: IStoreState) =>
  state.packagesAvailability.lodgingIdInFocus;

export const getHotelAvailabilityLodgingIdHovered = (state: IStoreState) =>
  state.packagesAvailability.lodgingIdHovered;

export const getPackageAvailabilitySearchDestinationLocationResult = (
  state: IStoreState
) => {
  return state.packagesAvailability.searchDestinationResult;
};

export const getPackageAvailabilitySearchLocation = (state: IStoreState) =>
  state.packagesAvailability.searchLocation;

export const getTravelersCount = createSelector(
  getPackageAvailabilityAdultsCount,
  getPackageAvailabilityChildren,
  getPackageAvailabilityInfants,
  (adultsCount, children, infants) => {
    return adultsCount + children?.length + infants?.length;
  }
);

export const getPackagesAvailabilityLodgingsSortOption = (state: IStoreState) =>
  state.packagesAvailability.sortOption;

export const getHotelAvailabilityStarRatingsFilter = (state: IStoreState) =>
  state.packagesAvailability.starRatings;

export const getPackagesAvailabilityPaymentMethods = (state: IStoreState) =>
  state.packagesAvailability.paymentMethods;

export const hasChangedHotelAvailabilityStarRatingsFilter = createSelector(
  getHotelAvailabilityStarRatingsFilter,
  (starRatings) => {
    return !isEqual(starRatings, initialFilterState.starRatings);
  }
);

export const getHotelAvailabilityAmenitiesFilter = (state: IStoreState) =>
  state.packagesAvailability.amenities;

export const hasChangedHotelAvailabilityAmenitiesFilter = createSelector(
  getHotelAvailabilityAmenitiesFilter,
  (amenities) => {
    return !isEqual(amenities, initialFilterState.amenities);
  }
);
export const getHotelAvailabilityCurrency = createSelector(
  getPackagesAvailabilityLodgings,
  getPackagesByLodgingId,
  (lodgings, lodgingsById): string => {
    if (lodgings) {
      const lodgingWithPrice = lodgings.find(
        (lodging) =>
          lodgingsById?.[lodging.lodging?.id]?.packageDetails.pricing.totalPrice
      );

      if (lodgingWithPrice?.price) {
        return lodgingWithPrice.price.nightlyPrice.fiat.currencyCode;
      }
    }

    return "USD";
  }
);

export const getHotelAvailabilityMaxPriceFilter = (state: IStoreState) =>
  state.packagesAvailability.maxPrice;

export const getHotelAvailabilityMinMaxPriceRange = createSelector(
  getPackagesAvailabilityLodgings,
  getPackagesByLodgingId,
  (lodgings, lodgingsById): IPriceRange | null => {
    if (!lodgings || lodgings.length === 0) {
      return null;
    }
    let curMin =
      lodgingsById?.[lodgings[0].lodging.id]?.packageDetails.pricing.totalPrice
        .fiat.value;

    let curMax =
      lodgingsById?.[lodgings[0].lodging.id]?.packageDetails.pricing.totalPrice
        .fiat.value;

    lodgings.forEach((lodging) => {
      const lodgingPrice =
        lodgingsById?.[lodging.lodging.id]?.packageDetails.pricing.totalPrice
          .fiat.value;
      if (lodgingPrice) {
        if (!curMin || lodgingPrice < curMin) curMin = lodgingPrice;
        if (!curMax || lodgingPrice > curMax) curMax = lodgingPrice;
      }
    });

    // price happens to be optional, so there could be a case where all lodgings have no price
    if (curMin === undefined || curMax === undefined) return null;

    return {
      min: curMin,
      max: curMax,
    };
  }
);

export const hasChangedHotelAvailabilityMaxPriceFilter = createSelector(
  getHotelAvailabilityMaxPriceFilter,
  (maxPrice) => {
    return maxPrice !== initialFilterState.maxPrice;
  }
);

export const getHotelAvailabilityFreeCancelFilter = (state: IStoreState) =>
  state.packagesAvailability.freeCancel;

export const hasChangedHotelAvailabilityFreeCancelFilter = createSelector(
  getHotelAvailabilityFreeCancelFilter,
  (freeCancel) => freeCancel !== initialFilterState.freeCancel
);

export const getHotelAvailabilityHotelNameFilter = (state: IStoreState) =>
  state.packagesAvailability.hotelName;

export const hasChangedHotelAvailabilityHotelNameFilter = createSelector(
  getHotelAvailabilityHotelNameFilter,
  (hotelName) => {
    return !isEqual(hotelName, initialFilterState.hotelName);
  }
);

export const getHotelAvailabilityPolicyFilter = (state: IStoreState) =>
  state.packagesAvailability.isInPolicy;

export const hasChangedHotelAvailabilityPolicyFilter = createSelector(
  getHotelAvailabilityPolicyFilter,
  (isInPolicy) => isInPolicy !== initialFilterState.isInPolicy
);

export const getHotelAvailabilityLoyaltyProgramsFilter = (state: IStoreState) =>
  state.packagesAvailability.loyaltyPrograms;

export const getAppliedHotelAvailabilityFilterCount = createSelector(
  hasChangedHotelAvailabilityAmenitiesFilter,
  hasChangedHotelAvailabilityFreeCancelFilter,
  hasChangedHotelAvailabilityHotelNameFilter,
  hasChangedHotelAvailabilityMaxPriceFilter,
  hasChangedHotelAvailabilityStarRatingsFilter,
  (
    hasChangedAmenitiesFilter,
    hasChangedFreeCancelFilter,
    hasChangedHotelNameFilter,
    hasChangedMaxPriceFilter,
    hasChangedStarRatingsFilter
  ) =>
    [
      hasChangedAmenitiesFilter,
      hasChangedFreeCancelFilter,
      hasChangedHotelNameFilter,
      hasChangedStarRatingsFilter,
      hasChangedMaxPriceFilter,
    ].filter((applied) => applied).length
);

export const getDeduplicatedLodgings = createSelector(
  getPackagesAvailabilityLodgings,
  (lodgings) => {
    let filteredLodgings = [];
    if (lodgings?.length) {
      // filter out premier collection versions
      filteredLodgings = lodgings.filter(
        (lodging) => lodging.lodgingCollection !== LodgingCollectionEnum.Premier
      );

      return filteredLodgings;
    } else {
      return [];
    }
  }
);

const getAllHotelAvailabilityFilters = createSelector(
  getHotelAvailabilityAmenitiesFilter,
  getHotelAvailabilityStarRatingsFilter,
  getHotelAvailabilityMaxPriceFilter,
  getHotelAvailabilityHotelNameFilter,
  getHotelAvailabilityFreeCancelFilter,
  getHotelAvailabilityPolicyFilter,
  getHotelAvailabilityLoyaltyProgramsFilter,
  (
    amenities,
    starRatings,
    maxPrice,
    hotelName,
    freeCancel,
    isInPolicy,
    loyaltyPrograms
  ) => {
    return {
      amenities,
      starRatings,
      maxPrice,
      hotelName,
      freeCancel,
      isInPolicy,
      loyaltyPrograms,
    };
  }
);

export const getFilteredHotelAvailabilityLodgings = createSelector(
  getAllHotelAvailabilityFilters,
  getDeduplicatedLodgings,
  getPackagesByLodgingId,
  (filters, lodgings, lodgingsById): Lodging[] => {
    const {
      amenities,
      starRatings,
      maxPrice,
      hotelName,
      freeCancel,
      isInPolicy,
      loyaltyPrograms,
    } = filters;

    const meetsFilterPredicates = (lodging: Lodging) => {
      if (
        !performAmenitiesFilter(lodging, amenities) ||
        !performStarRatingsFilter(lodging, starRatings) ||
        (lodgingsById?.[lodging.lodging.id] &&
          !performMaxPriceFilter(
            lodging,
            lodgingsById?.[lodging.lodging.id],
            maxPrice
          )) ||
        !performHotelNameFilter(lodging, hotelName) ||
        !performPolicyFilter(lodging, isInPolicy) ||
        !performFreeCancelFilter(lodging, freeCancel) ||
        !performLoyaltyProgramFilter(lodging, loyaltyPrograms)
      ) {
        return false;
      }

      return true;
    };
    return (lodgings ?? []).filter(meetsFilterPredicates);
  }
);

export const getIsFilteredHotelAvailabilityLodgingsEmpty = createSelector(
  getFilteredHotelAvailabilityLodgings,
  getPackagesAvailabilityCallState,
  (lodgings, availabilityCallState): boolean => {
    return (
      lodgings.length === 0 &&
      availabilityCallState === PackagesAvailabilityCallState.Complete
    );
  }
);

export const getFilteredAndSortedHotelAvailabilityLodgings = createSelector(
  getFilteredHotelAvailabilityLodgings,
  getPackagesAvailabilityLodgingsSortOption,
  getPackagesByLodgingId,
  (lodgings, sortOption, lodgingsById): Lodging[] => {
    const { availableLodgings, unavailableLodgings } = lodgings.reduce(
      (prev, curr) => {
        const available =
          typeof curr.available === "undefined" ? true : curr.available;

        if (available) prev.availableLodgings.push(curr);
        else prev.unavailableLodgings.push(curr);

        return prev;
      },
      {
        availableLodgings: [] as Lodging[],
        unavailableLodgings: [] as Lodging[],
      }
    );

    const sortedAvailableHotels = (() => {
      switch (sortOption) {
        case PackagesHotelsAvailabilitySortOption.Pricing:
          return orderByPriceLowToHigh(availableLodgings, lodgingsById ?? {});
        case PackagesHotelsAvailabilitySortOption.StarRating:
          return orderByStarRatingHighToLow(availableLodgings);
        case PackagesHotelsAvailabilitySortOption.Recommended:
          return availableLodgings;
      }
    })();

    return sortedAvailableHotels.concat(unavailableLodgings);
  }
);

export const getIsUnfilteredHotelAvailabilityLodgingsEmpty = createSelector(
  getDeduplicatedLodgings,
  getPackagesAvailabilityCallState,
  (lodgings, availabilityCallState): boolean => {
    return (
      lodgings?.length === 0 &&
      availabilityCallState === PackagesAvailabilityCallState.Complete
    );
  }
);

export const getIsFilteredHotelAvailabilityLodgingsEmptyAndDoneSearching =
  createSelector(
    getFilteredHotelAvailabilityLodgings,
    getPackagesAvailabilityCallState,
    (lodgings, availabilityCallState): boolean => {
      return (
        lodgings.length === 0 &&
        (availabilityCallState === PackagesAvailabilityCallState.Complete ||
          availabilityCallState ===
            PackagesAvailabilityCallState.FollowUpSearchCallSuccess)
      );
    }
  );

export const getRecommendedFlights = (state: IStoreState) =>
  (
    state.packagesAvailability
      .availabilityResponse as SearchPackagesResponseInitial
  )?.benchmarkFlight.tripDetails;

export const getExistingPackagesAvailabilityRequestParameters = createSelector(
  getPackageAvailabilitySearchDestinationLocationResult,
  getPackagesAvailabilityDepartureDate,
  getPackagesAvailabilityReturnDate,
  getPackageAvailabilityAdultsCount,
  getPackageAvailabilityChildren,
  getPackageAvailabilityInfants,
  getPackagesAvailabilityFareclassOptionFilter,
  getPackagesAvailabilityStopsOption,

  (
    location,
    departureDate,
    returnDate,
    adultsCount,
    children,
    infants,
    fareClassOptionFilter,
    stopsOption
  ): {
    location: IResult | null;
    departureDate: Date | null;
    returnDate: Date | null;
    adultsCount: number | null;
    children: number[];
    infants: {
      age: number;
      inSeat: boolean;
    }[];
    fareClassOptionFilter: FareclassOptionFilter;
    stopsOption: SliceStopCountFilter;
  } => ({
    location,
    departureDate,
    returnDate,
    adultsCount,
    children,
    infants,
    fareClassOptionFilter,
    stopsOption,
  })
);

export const areRequestParametersChanged = createSelector(
  getExistingPackagesAvailabilityRequestParameters,
  getDestination,
  getDepartureDate,
  getReturnDate,
  getTravelers,
  getFareclassOptionFilter,
  getStopsOption,
  (
    requestParameters,
    locationSearched,
    fromDateSearched,
    untilDateSearched,
    travelers,
    fareClassOptionFilter,
    stopsOption
  ): boolean => {
    return (
      (locationSearched !== null &&
        !isEqual(locationSearched, requestParameters.location)) ||
      !isEqual(fromDateSearched, requestParameters.departureDate) ||
      !isEqual(untilDateSearched, requestParameters.returnDate) ||
      !isEqual(travelers.adultsCount, requestParameters.adultsCount) ||
      !isEqual(travelers.children, requestParameters.children) ||
      !isEqual(travelers.infants, requestParameters.infants) ||
      !isEqual(
        fareClassOptionFilter,
        requestParameters.fareClassOptionFilter
      ) ||
      !isEqual(stopsOption, requestParameters.stopsOption)
    );
  }
);

export const areHotelRequestParametersChanged = createSelector(
  getExistingPackagesAvailabilityRequestParameters,
  getDestination,
  getDepartureDate,
  getReturnDate,

  (
    requestParameters,
    locationSearched,
    fromDateSearched,
    untilDateSearched
  ): boolean => {
    return (
      (locationSearched !== null &&
        !isEqual(locationSearched, requestParameters.location)) ||
      !isEqual(fromDateSearched, requestParameters.departureDate) ||
      !isEqual(untilDateSearched, requestParameters.returnDate)
    );
  }
);

export const getPackageHotelQueryParams = createSelector(
  getPackagesAvailabilityDepartureDate,
  getPackagesAvailabilityReturnDate,
  getPackageAvailabilityAdultsCount,
  getPackageAvailabilityChildren,
  getPackageAvailabilityInfants,
  getStopsOption,
  getFareclassOptionFilter,
  getPackageAvailabilitySearchToken,
  (
    fromDate,
    untilDate,
    adultsCount,
    children,
    infants,
    stopsOption,
    fareClassOptionFilter,
    searchToken
  ) => ({
    fromDate,
    untilDate,
    adultsCount,
    children,
    infants,
    stopsOption,
    fareClassOptionFilter,
    searchToken,
  })
);

export const getPackageEntryProperties = createSelector(
  getDestination,
  getPackagesAvailabilityDepartureDate,
  getPackagesAvailabilityReturnDate,
  getPackageAvailabilityAdultsCount,
  getPackageAvailabilityChildren,
  getPackageAvailabilityInfants,
  getStopsOption,
  getPackagesAvailabilityFareclassOptionFilter,
  (
    destination,
    departureDate,
    returnDate,
    adultsCount,
    children,
    infants,
    stopsOption,
    fareClassOptionFilter
  ): ITrackingProperties<PackageEntryProperties> => {
    return {
      properties: {
        market: destination?.label || "",
        country:
          destination?.subLabel || destination?.label?.split(", ")?.pop() || "",
        los:
          departureDate && returnDate
            ? `${dayjs(returnDate).diff(departureDate, "day")} days`
            : undefined,
        number_of_adults: adultsCount,
        number_of_children: children.length,
        number_of_infants: infants.length,
        total_travelers: adultsCount + children.length + infants.length,
        non_stop: stopsOption === SliceStopCountFilter.NONE,
        fare_class: getSelectedFareClass(fareClassOptionFilter),
      },
      encryptedProperties: [],
    };
  }
);

export const getPackagesRewardsAccounts = (state: IStoreState) =>
  state.packagesRewards.rewardsAccounts;

export const getPackagesRewardsIsFirstLaunch = (state: IStoreState) =>
  state.packagesRewards.isFirstLaunch;

export const getPackagesRewardsAgentEmail = (state: IStoreState) =>
  state.packagesRewards.agentEmail;

export const getPackagesRewardsUserSelectedAccountReferenceId = (
  state: IStoreState
) => state.packagesRewards.selectedAccountReferenceId;

export const getPackagesRewardsFetchRewardsAccountsCallState = (
  state: IStoreState
) => state.packagesRewards.fetchRewardsAccountsCallState;

export const getSelectedAccount = createSelector(
  getPackagesRewardsAccounts,
  getPackagesRewardsUserSelectedAccountReferenceId,
  (rewardsAccounts, referenceId): RewardsAccount | undefined => {
    return rewardsAccounts.find(
      (account) => account.accountReferenceId === referenceId
    );
  }
);

export const getUnavailableLodgingCount = createSelector(
  getDeduplicatedLodgings,
  (lodgings) =>
    lodgings?.filter((lodging) =>
      typeof lodging.available === "undefined" ? false : !lodging.available
    ).length || 0
);

export const getAvailableLodgingCount = createSelector(
  getDeduplicatedLodgings,
  (lodgings) =>
    lodgings?.filter((lodging) =>
      typeof lodging.available === "undefined" ? false : lodging.available
    ).length || 0
);

export const getLuxuryCollectionLodgingCount = createSelector(
  getDeduplicatedLodgings,
  (lodgings) =>
    lodgings?.filter(
      (lodging) => lodging.lodgingCollection === LodgingCollectionEnum.Premier
    ).length
);

export const getLifestyleCollectionLodgingCount = createSelector(
  getDeduplicatedLodgings,
  (lodgings) =>
    lodgings?.filter(
      (lodging) => lodging.lodgingCollection === LodgingCollectionEnum.Lifestyle
    ).length
);

export const getLodgingPromotionsCount = createSelector(
  getDeduplicatedLodgings,
  (lodgings) =>
    lodgings?.filter((lodging) => !!lodging.bestPromotionThisLodging).length
);

export const getHotelEntryProperties = createSelector(
  getPackagesRewardsAgentEmail,
  getDestination,
  getPackagesAvailabilityDepartureDate,
  getPackagesAvailabilityReturnDate,
  getPackagesRewardsIsFirstLaunch,
  getPackagesRewardsAccounts,
  getPackageAvailabilityAdultsCount,
  getPackageAvailabilityChildren,
  getPackageAvailabilityInfants,
  getPackagesAvailabilityRoomsCount,
  getTravelWalletCredit,
  getCreditBreakdown,
  getPackagesAvailabilityPaymentMethods,

  (
    agentEmail,
    destination,
    fromDate,
    untilDate,
    isFirstLaunch,
    rewardsAccounts,
    adultsCount,
    children,
    infants,
    roomsCount,
    credit,
    creditBreakdown,
    paymentMethods
  ): ITrackingProperties<HotelEntryProperties> => {
    return {
      properties: {
        delegated_to: agentEmail || "",
        first_launch: isFirstLaunch,
        market: destination?.label || "",
        country:
          destination?.subLabel || destination?.label?.split(", ")?.pop() || "",
        number_of_properties: 0,
        number_of_guests: adultsCount + children.length + infants.length,
        hotel_advance: fromDate
          ? `${dayjs(fromDate).diff(dayjs(), "day")} days`
          : "",
        advance: fromDate ? dayjs(fromDate).diff(dayjs(), "day") : undefined,
        rooms_searched: roomsCount,
        pets_searched: 0,
        rewards_accounts: rewardsAccounts
          .map((r) => r.productDisplayName)
          .join(","),
        has_credits: !!credit,
        credit_balance: !!credit?.amount?.amount
          ? Math.abs(credit.amount.amount)
          : 0,
        vx_statement_credit_balance:
          creditBreakdown
            ?.filter((b) => b.CreditDetail === "Statement")
            .reduce(
              (prev, curr) =>
                prev + (curr as StatementCreditDetail).usableAmount.amount,
              0
            ) || 0,
        los:
          fromDate && untilDate
            ? `${dayjs(untilDate).diff(fromDate, "day")} days`
            : undefined,
        los_count:
          fromDate && untilDate
            ? dayjs(untilDate).diff(fromDate, "day")
            : undefined,
        card_on_file: paymentMethods.length > 0,
        ...destination?.trackingPropertiesV2?.properties,
      },
      encryptedProperties: [
        destination?.trackingPropertiesV2?.encryptedProperties ?? "",
      ],
    };
  }
);

export const getPackagesViewedHotelListProperties = createSelector(
  getHotelEntryProperties,
  getSelectedAccount,
  getUnavailableLodgingCount,
  getAvailableLodgingCount,
  getLuxuryCollectionLodgingCount,
  getLodgingPromotionsCount,
  getLifestyleCollectionLodgingCount,
  getPackageAvailabilitySearchDestinationLocationResult,
  getFilteredHotelAvailabilityLodgings,
  (
    properties,
    account,
    unavailableLodgingCount,
    availableLodgingCount,
    luxuryLodgingCount,
    lodgingPromotionsCount,
    lifestyleLodgingCount,
    location,
    filteredLodgings
  ): ITrackingProperties<ViewedHotelListProperties> => {
    return {
      properties: {
        ...properties.properties,
        preferences_applied: undefined,
        account_type_selected: account?.productDisplayName || "",
        unavailable_hotels: unavailableLodgingCount,
        available_hotels: availableLodgingCount,
        account_use_type: account?.accountUseType,
        account_allow_rewards_redemption: account?.allowRewardsRedemption,
        pc_shown: luxuryLodgingCount || 0,
        has_pc_properties: (luxuryLodgingCount || 0) > 0,
        has_lodging_promotions: lodgingPromotionsCount,
        lc_shown: lifestyleLodgingCount || 0,
        has_lc_properties: (lifestyleLodgingCount || 0) > 0,
        city_name: (
          location?.id as { lodgingSelection: Place }
        )?.lodgingSelection.placeTypes.includes("locality")
          ? location?.label
          : undefined,
        state:
          filteredLodgings.length > 0
            ? filteredLodgings[0].lodging.state?.name
            : undefined,
        no_availability: !filteredLodgings.length,
        google_place_id: (location?.id as IIdLodgings)?.lodgingSelection
          ?.placeId,
      },
      encryptedProperties: [],
    };
  }
);
