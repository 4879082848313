import React from "react";
import { RouteComponentProps } from "react-router";
import {
  GenericInfoPopup,
  Icon,
  IconName,
  useDeviceTypes,
  usePrevious,
} from "halifax";
import { BookingErrorModalConnectorProps } from "./container";
import "./styles.scss";
import {
  CardPaymentEventTypes,
  CartFulfillEventType,
  cartFulfillSelectors,
  CartQuoteEventType,
  cartQuoteSelectors,
  getParentState,
  ParentState,
  useCheckoutState,
  useCheckoutStateSelector,
} from "@capone/checkout";
import { Event, TEvent } from "../../state/events";
import { PackagesMachineContext } from "../../state/types";
import { getErrorModalProps } from "./utils";
import { PurchaseError } from "@b2bportal/purchase-api";
import { trackEvent } from "../../../../api/v0/trackEvent";
import {
  FlightShopStep,
  MODAL_ALERT,
  ModalAlertProperties,
  ModalCategoryType,
  ModalScreens,
} from "redmond";
import clsx from "clsx";
import { goToAvailability } from "../../../hotel-shop/utils/queryStringHelpers";

export interface IBookingErrorModalProps
  extends BookingErrorModalConnectorProps,
    RouteComponentProps {}

export const BookingErrorModal = ({
  history,
  origin,
  departureDate,
  returnDate,
  selectedLodging,
  stopsOption,
  travelers,
  fareClassOptionFilter,
  setPackagesFlightShopProgress,
}: IBookingErrorModalProps) => {
  const { matchesMobile } = useDeviceTypes();

  const quoteErrorOpen = useCheckoutStateSelector(
    cartQuoteSelectors.getOpenCartQuoteErrorModal
  );
  const quoteError = useCheckoutStateSelector(
    cartQuoteSelectors.getCartQuoteError
  );
  const fulfillErrorOpen = useCheckoutStateSelector(
    cartFulfillSelectors.getOpenCartFulfillErrorModal
  );
  const fulfillError = useCheckoutStateSelector(
    cartFulfillSelectors.getCartFulfillError
  );

  const priceChange = useCheckoutStateSelector(
    cartQuoteSelectors.getCartQuotePriceChange
  );

  const open =
    fulfillErrorOpen ||
    quoteErrorOpen ||
    !!(priceChange?.hasDifference && !priceChange.acknowledged);

  const error = fulfillError || quoteError;

  const previousError = usePrevious(error);
  const previousQuoteError = usePrevious(quoteError);
  const previousFulfillError = usePrevious(fulfillError);

  const [state, send] = useCheckoutState<TEvent, PackagesMachineContext>();

  const parentState = getParentState(state.value) as ParentState;

  const clearError = () => {
    send(CartQuoteEventType.CLEAR_CART_QUOTE_ERROR);
    send(CartFulfillEventType.CLEAR_CART_FULFILL_ERROR);
  };

  const updateFlightTravelers = () => {
    send(CartQuoteEventType.OPEN_PASSENGER_PICKER);
    clearError();
  };

  const updateHotelTravelers = () => {
    send(Event.GO_TO_HOTEL_PASSENGER_SELECT);
    clearError();
  };

  const handleAcknowledgePriceChange = () => {
    send(CardPaymentEventTypes.ACKNOWLEDGE_PRICE_CHANGE);
  };

  const handleRetryQuote = () => {
    send(CartQuoteEventType.RETRY_QUOTE);
  };

  const handleGoToAvailability = () => {
    setPackagesFlightShopProgress(FlightShopStep.ChooseDeparture);
    goToAvailability({
      history,
      lodging: selectedLodging,
      fromDate: departureDate,
      untilDate: returnDate,
      origin,
      adultsCount: travelers.adultsCount,
      children: travelers.children,
      infants: travelers.infants,
      stopsOption,
      fareClassOptionFilter,
    });
  };

  const errorModalProps = getErrorModalProps(
    error?.type || previousError?.type, // fallback to previous error state to prevent flashing of default error on modal close
    (error?.data || previousError?.data) as PurchaseError[],
    priceChange,
    !!(quoteError || previousQuoteError),
    !!(fulfillError || previousFulfillError),
    updateFlightTravelers,
    updateHotelTravelers,
    handleAcknowledgePriceChange,
    handleRetryQuote,
    handleGoToAvailability
  );

  const modalEventProperties: ModalAlertProperties = {
    type: error?.type || "",
    primary_button:
      errorModalProps.buttons?.[0]?.buttonText?.replaceAll(" ", "_") || "",
    secondary_button:
      errorModalProps.buttons?.[1]?.buttonText.replaceAll(" ", "_") || "",
    screen: ModalScreens.PACKAGES_CHECKOUT,
    category: ModalCategoryType.TROUBLE,
    modal_subtitle: errorModalProps.subtitle as string,
    modal_title: errorModalProps.title as string,
    agent_subtitle: errorModalProps.subtitle as string,
    agent_title: errorModalProps.title as string,
    funnel: "packages",
    step: parentState,
  };

  React.useEffect(() => {
    if (open)
      trackEvent({
        eventName: MODAL_ALERT,
        properties: {
          ...modalEventProperties,
        },
      });
  }, [open]);

  return (
    <GenericInfoPopup
      open={open}
      image={<Icon className="error-icon" name={IconName.ErrorState} />}
      className={clsx("packages-book-error-modal", { mobile: matchesMobile })}
      zIndex={1301}
      {...errorModalProps}
    />
  );
};
