import React, { useContext } from "react";
import {
  B2BSpinner,
  TravelerSelectWorkflow,
  LoadingIndicator,
  TravelerSelectStep,
  ToggleActions,
} from "halifax";
import {
  IPerson,
  PersonId,
  ADD_FREQUENT_FLYER,
  CLICKED_ADD_TRAVELER,
  ClickedAddTravelerProperties,
} from "redmond";
import clsx from "clsx";
import "./styles.scss";
import { FlightBookPassengerSelectionConnectorProps } from "./container";
import * as constants from "./textConstants";
import { ClientContext } from "../../../../App";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";
import { InfantSeatPickerModal } from "../InfantSeatPickerModal";
import {
  addTrackingProperties,
  useExperiments,
} from "../../../../context/experiments";
import { config } from "../../../../api/config";
import {
  FlightPassengerEventTypes,
  FlightPassengerSelectors,
  getParentState,
  LodgingSelectors,
  ParentState,
  PassengerErrorModalTypes,
  useCheckoutState,
  useCheckoutStateSelector,
  useChildMachineSelector,
  useChildMachineState,
} from "@capone/checkout";
import { Event, TEvent } from "../../state/events";
import { PackagesMachineContext } from "../../state/types";
import { personToIPerson } from "../../../../utils/personToIPerson";
import {
  PackagesPriceBreakdown,
  PassengerErrorModal,
  PassportModal,
} from "../";
import "./styles.scss";
import { RouteComponentProps } from "react-router-dom";
import { Box, Typography } from "@material-ui/core";
import { DesktopPackageBookValidationError } from "../DesktopPackagesBookWorkflow";

const getTravelerWorkflowStep = ({
  openPassengerFormModal,
  isMobile,
  parentState,
}: {
  openPassengerFormModal: boolean;
  isMobile: boolean;
  parentState: ParentState;
}): TravelerSelectStep => {
  switch (true) {
    case openPassengerFormModal:
      return TravelerSelectStep.TravelerInfoForm;
    case isMobile && parentState !== ParentState.review:
      return TravelerSelectStep.TravelerSelect;
    default:
      return TravelerSelectStep.Main;
  }
};

export interface IFlightBookPassengerSelectionProps
  extends FlightBookPassengerSelectionConnectorProps,
    RouteComponentProps {
  isMobile?: boolean;
  validationErrorTypes?: DesktopPackageBookValidationError[];
  showNonModalContent?: boolean;
}

export const FlightBookPassengerSelection = ({
  history,
  isMobile,
  validationErrorTypes,
  showNonModalContent,
}: IFlightBookPassengerSelectionProps) => {
  const [state, send] = useCheckoutState<TEvent, PackagesMachineContext>();

  const parentState = getParentState(state.value) as ParentState;

  const [, passengerChildMachineSend] = useChildMachineState<
    TEvent,
    PackagesMachineContext
  >(ParentState.passengerInformation);

  const openPassengerFormModal = useChildMachineSelector(
    ParentState.passengerInformation,
    FlightPassengerSelectors.getOpenPassengerFormModal
  );

  const travelers = useChildMachineSelector(
    ParentState.passengerInformation,
    FlightPassengerSelectors.getUserPassengers
  );

  // all travelers excluding lap infants
  const selectedTravelerIds = useChildMachineSelector(
    ParentState.passengerInformation,
    FlightPassengerSelectors.getSelectedPassengerIds
  );

  const selectedLapInfantIds = useChildMachineSelector(
    ParentState.passengerInformation,
    FlightPassengerSelectors.getSelectedLapInfantIds
  );

  const passengersLoading = useChildMachineSelector(
    ParentState.passengerInformation,
    FlightPassengerSelectors.getIsPassengerInformationLoading
  );

  const hasError = useChildMachineSelector(
    ParentState.passengerInformation,
    FlightPassengerSelectors.getPassengerErrorOpen
  );

  const currentPassenger = useChildMachineSelector(
    ParentState.passengerInformation,
    FlightPassengerSelectors.getCurrentUserPassenger
  );

  const currentInfantId = useChildMachineSelector(
    ParentState.passengerInformation,
    FlightPassengerSelectors.getCurrentInfantId
  );

  const selectedPassengerCount = useChildMachineSelector(
    ParentState.passengerInformation,
    FlightPassengerSelectors.getAllSelectedPassengerIds
  ).length;

  const selectedRoomCapacity =
    useCheckoutStateSelector(LodgingSelectors.getSelectedRoomProduct)?.bedTypes
      .occupancy || 0;

  const searchedGuestCount = useCheckoutStateSelector(
    LodgingSelectors.getGuestCount
  );

  const numPassengersAlertDismissed = useChildMachineSelector(
    ParentState.passengerInformation,
    FlightPassengerSelectors.getNumPassengerAlertDismissed
  );

  const expState = useExperiments();

  const handleSelectPassenger = (
    passengerId: PersonId,
    singleTravelerWorkflow?: boolean
  ) => {
    if (hasError) {
      passengerChildMachineSend(Event.CLEAR_PASSENGER_INFORMATION_ERROR);
    }

    const selectedPassenger = travelers.find((p) => p.id === passengerId);

    if (selectedPassenger) {
      passengerChildMachineSend({
        type: Event.SET_CURRENT_PASSENGER,
        passenger: selectedPassenger as IPerson,
      });
    }
    passengerChildMachineSend({
      type: Event.SELECT_PASSENGER,
      passengerId,
      singleTravelerWorkflow,
    });
  };

  const handleUpdatePassenger = (passenger: IPerson) => {
    passengerChildMachineSend({
      type: Event.UPDATE_PASSENGER,
      person: {
        ...passenger,
        // validation will fail if any of these fields are empty so include all or nothing
        passport:
          passenger.passport?.countryOfIssue &&
          passenger.passport?.expiration &&
          passenger.passport?.number
            ? passenger.passport
            : undefined,
      },
      onUpdate: true,
    });
  };

  const handleDeletePassenger = (personId: string) => {
    passengerChildMachineSend({ type: Event.DELETE_PASSENGER, personId });
  };

  const handleEditClick = (passenger: IPerson) => {
    if (parentState === ParentState.review) {
      // edit travelers from mobile review screen
      send({
        type: FlightPassengerEventTypes.OPEN_FORM_AND_SET_PASSENGER,
        passenger,
      });
    } else {
      passengerChildMachineSend({
        type: Event.SET_CURRENT_PASSENGER,
        passenger,
      });
      passengerChildMachineSend(Event.OPEN_PASSENGER_FORM);
    }
  };

  const handleFormClose = () => {
    passengerChildMachineSend(Event.OPEN_PASSENGER_PICKER);
  };

  const handleClickAddNewTraveler = () => {
    passengerChildMachineSend({
      type: Event.SET_CURRENT_PASSENGER,
      passenger: undefined,
    });
    passengerChildMachineSend(Event.OPEN_PASSENGER_FORM);
  };

  const handleInfantPickerModalClose = () => {
    passengerChildMachineSend(Event.CLEAR_CURRENT_INFANT_ID);
  };

  const handleInfantPickerModalContinue = (seatType: "OwnSeat" | "OnLap") => {
    passengerChildMachineSend({
      type: Event.ON_INFANT_MODAL_CONTINUE,
      seatType,
    });
    handleInfantPickerModalClose();
  };

  const selectedTravelerCountExceedsRoomCapacity =
    selectedPassengerCount > selectedRoomCapacity;
  const selectedTravelerCountLessThanSearched =
    !numPassengersAlertDismissed && selectedPassengerCount < searchedGuestCount;

  const canContinue = isMobile
    ? !(
        selectedTravelerCountExceedsRoomCapacity ||
        selectedTravelerCountLessThanSearched
      )
    : true;

  const handleContinue = () => {
    if (canContinue) {
      passengerChildMachineSend(Event.NEXT);
    } else {
      if (selectedTravelerCountExceedsRoomCapacity) {
        passengerChildMachineSend({
          type: FlightPassengerEventTypes.SET_PASSENGER_INFORMATION_ERROR,
          error: {
            type: PassengerErrorModalTypes.SelectedPassengersExceedRoomCapacity,
            data: {},
          },
        });
      } else if (selectedTravelerCountLessThanSearched) {
        passengerChildMachineSend({
          type: FlightPassengerEventTypes.SET_PASSENGER_INFORMATION_ERROR,
          error: {
            type: PassengerErrorModalTypes.SearchPassengerNumNotReached,
            data: {},
          },
        });
      }
    }
  };

  const handleGoBack = () => {
    history.goBack();
  };

  const { sessionInfo } = useContext(ClientContext);

  return (
    <>
      {passengersLoading ? (
        <LoadingIndicator
          className="flight-book-passenger-selection-loading-indicator"
          indicatorSize="small"
          indicator={B2BSpinner}
          message={constants.UPDATE_TEXT}
        />
      ) : (
        <TravelerSelectWorkflow
          showAdditionalInfoSection
          showFrequentFlyerSection
          showGenderField
          showNationalityField
          className={clsx("packages-flight-book-passenger-selection-root", {
            error: validationErrorTypes?.includes("flight-travelers"),
          })}
          travelers={travelers.map(personToIPerson)}
          progress={getTravelerWorkflowStep({
            openPassengerFormModal,
            isMobile: !!isMobile,
            parentState,
          })}
          setProgress={() => {}} // progress is derived from state machine
          userInfo={sessionInfo?.userInfo}
          titles={{
            travelerInfoTitle:
              isMobile && parentState === ParentState.review
                ? constants.TRAVELER_INFO_TEXT
                : constants.TRAVELER_INFO_TITLE_UPDATED,
            travelerInfoSubtitle: isMobile
              ? undefined
              : constants.TRAVELER_INFO_SUBTITLE,
            frequentFlyerTitle: constants.FREQUENT_FLYER_TITLE,
            additionalInfoTitle: constants.ADDITIONAL_INFO_TITLE,
            adultTitle: constants.ADULT_TITLE,
            childTitle: constants.CHILD_TITLE,
            infantSeatTitle: constants.INFANT_SEAT_TITLE,
            infantLapTitle: constants.INFANT_LAP_TITLE,
            addTravelers: constants.ADD_TRAVELERS_TEXT_UPDATED,
            editTravelerTitle: constants.EDIT_TRAVELER_TEXT,
            travelerInfoFormSubtitle: constants.ADD_TRAVELERS_SUBTITLE,
            travelerInfoSectionTitle: constants.TRAVELER_INFO_TEXT,
            passportTitle: constants.PASSPORT_TITLE,
            passportSubtitle: constants.PASSPORT_SUBTITLE,
            passportSubtitle2: constants.PASSPORT_SUBTITLE_2,
            completeTravelerProfileTitle: constants.COMPLETE_PROFILE_TITLE,
            completeTravelerProfileSubtitle:
              constants.COMPLETE_PROFILE_SUBTITLE,
          }}
          selectedTravelerIds={[
            ...selectedTravelerIds,
            ...selectedLapInfantIds,
          ]}
          handleSelectTraveler={handleSelectPassenger}
          handleUpdatePassenger={(
            traveler: IPerson,
            _hideProfileAction: ToggleActions,
            _entryPoint?: string,
            _updatePassport?: boolean,
            _isNewTraveler?: boolean
          ) => {
            const freqFlyerKeys = Object.keys(traveler.frequentFlyer);
            if (
              freqFlyerKeys.length > 0 &&
              traveler.frequentFlyer !==
                travelers.find((t) => t.id === traveler.id)?.frequentFlyer
            ) {
              trackEvent({
                eventName: ADD_FREQUENT_FLYER,
                properties: {
                  frequent_flyer_program:
                    freqFlyerKeys.length > 0
                      ? freqFlyerKeys[freqFlyerKeys.length - 1]
                      : "",
                },
              });
            }

            handleUpdatePassenger(traveler);
          }}
          handleDeletePassenger={handleDeletePassenger}
          isMobile={isMobile}
          buttonClassName="b2b"
          errorMessage={constants.ADD_TRAVELER_ERROR_MESSAGE}
          isFlights
          showPassportSection
          requireNationality
          onClickAddNewTraveler={() => {
            handleClickAddNewTraveler();
            trackEvent({
              eventName: CLICKED_ADD_TRAVELER,
              properties: addTrackingProperties(expState.trackingProperties, {
                entry_type: "checkout",
              } as ClickedAddTravelerProperties),
            });
          }}
          onClickEditTraveler={(traveler) => handleEditClick(traveler)}
          tenant={config.TENANT}
          trackEvent={trackEvent}
          setSelectedTravelerIds={() => {}}
          editPassenger={
            currentPassenger ? personToIPerson(currentPassenger) : undefined
          }
          onTravelerFormClose={handleFormClose}
          onContinue={isMobile ? handleContinue : undefined}
          onGoBack={isMobile ? handleGoBack : undefined}
          selectionScreenHeaderElement={
            <PackagesPriceBreakdown isMobile dropdown />
          }
          mobileTravelerRowType="checkbox"
          updatedDesign
          customHeader={
            <Box className="packages-flight-book-passenger-mobile-header">
              <Typography variant="h3" className="pax-header-primary-title">
                {constants.WHOS_FLYING_HEADING}
              </Typography>
              <hr />
              <Typography variant="h4" className="pax-header-secondary-title">
                {constants.TRAVELER_INFO_TITLE_UPDATED}
              </Typography>
              <Typography className="pax-header-subtitle">
                {constants.PAX_SUBTITLE}
              </Typography>
            </Box>
          }
          showMobilePopoverTransition={false}
          showMobileChangeCTA={false}
          bypassSelectForSingleTraveler={false}
          showNonModalContent={showNonModalContent}
        />
      )}
      <InfantSeatPickerModal
        currentInfantToSelectSeat={currentInfantId}
        handleInfantPickerModalClose={handleInfantPickerModalClose}
        onContinue={handleInfantPickerModalContinue}
      />
      <PassengerErrorModal />
      <PassportModal />
    </>
  );
};
