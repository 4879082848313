import React from "react";
import { debounce } from "lodash-es";
import { ButtonWrap, Icon, IconName, SliderDropdown } from "halifax";
import clsx from "clsx";
import "./styles.scss";
import * as textConstants from "./textConstants";
import {
  IFlightShopFilterSelector,
  initialFilterOptions,
} from "../../../../reducer";
import { Box, Typography } from "@material-ui/core";

interface IMaxPriceFilterSelectionProps {
  maxPriceFilter: number;
  setMaxPriceFilter: (maxPriceFilter: number) => void;
  flightShopFilters: IFlightShopFilterSelector;
  showDropdownContentOnly?: boolean;
  hasSetMaxPrice?: boolean;
  appliedLabel?: string;
  icon?: IconName;
  title?: string;
  subtitle?: string;
  showSliderSubtitle?: boolean;
}

export const MaxPriceFilterSelection = (
  props: IMaxPriceFilterSelectionProps
) => {
  const {
    maxPriceFilter,
    setMaxPriceFilter,
    showDropdownContentOnly,
    hasSetMaxPrice,
    appliedLabel,
    icon,
    title,
    subtitle,
    flightShopFilters,
  } = props;

  const debouncedAction = debounce(setMaxPriceFilter, 300);
  const [stateDebounceDispatchAction] = React.useState(() =>
    debounce(debouncedAction, 300, {
      leading: false,
      trailing: true,
    })
  );

  const { priceMin: minimumPrice, priceMax: maximumPrice } = flightShopFilters;
  const handleChange = (newValue: number) => {
    stateDebounceDispatchAction(
      newValue === maximumPrice.value
        ? initialFilterOptions.maxPriceFilter
        : newValue
    );
  };

  const sliderValue =
    maxPriceFilter === initialFilterOptions.maxPriceFilter
      ? maximumPrice.value
      : maxPriceFilter;

  return (
    <Box className="flight-shop-max-price-filter-container" tabIndex={0}>
      {!!title && showDropdownContentOnly && (
        <Box className="header-container">
          {icon && <Icon name={icon} />}
          <Box className="titles-wrapper">
            {title}
            {subtitle && (
              <Typography variant="body2" className="subtitle">
                {subtitle}
              </Typography>
            )}
          </Box>
        </Box>
      )}
      <SliderDropdown
        dropdownLabel={textConstants.PRICE_DROPDOWN_LABEL_TEXT(
          hasSetMaxPrice ? appliedLabel : undefined
        )}
        sliderHeader={
          !title ? textConstants.PRICE_SLIDER_HEADER_TEXT_EXP : undefined
        }
        sliderSubtitle={
          !subtitle ? textConstants.PRICE_SLIDER_SUBTITLE_TEXT : undefined
        }
        maxValue={maximumPrice.value}
        minValue={
          maximumPrice.value === minimumPrice.value ? 0 : minimumPrice.value
        }
        value={sliderValue}
        setValue={handleChange}
        sliderLabelFormat={(value) =>
          `${new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 0,
          }).format(value)}`
        }
        showDropdownContentOnly={showDropdownContentOnly}
        className={clsx("b2b-shop-filter", "filter-experiment", {
          "has-value": hasSetMaxPrice,
        })}
        popoverClassName={clsx(
          "b2b",
          "flight-availability-price-popover",
          "filter-experiment"
        )}
        dropdownIcon={
          hasSetMaxPrice && appliedLabel ? (
            <ButtonWrap
              onClick={(e) => {
                e.stopPropagation();
                handleChange(initialFilterOptions.maxPriceFilter);
              }}
            >
              <Icon name={IconName.XCircle} />
            </ButtonWrap>
          ) : undefined
        }
        anchorOrigin={(() => {
          return {
            vertical: "bottom",
            horizontal: "left",
          };
        })()}
        transformOrigin={(() => {
          return {
            vertical: "top",
            horizontal: "left",
          };
        })()}
        alwaysShowTooltip
        showResetButton={maxPriceFilter !== initialFilterOptions.maxPriceFilter}
        reset={() => handleChange(initialFilterOptions.maxPriceFilter)}
        showMinLabel
      />
    </Box>
  );
};
