export const SEARCH_AGAIN_BUTTON = "Search again";
export const GO_BACK_BUTTON = "Go back";
export const RESET_FILTERS_BUTTON = "Reset filters";

export const AVAILABILITY_ERROR_TITLE =
  "Uh oh! We were unable to process your search request.";
export const AVAILABILITY_ERROR_SUBTITLE = "Something went wrong.";

export const NO_FLIGHTS_ERROR_TITLE = "No packages found";
export const NO_FLIGHT_ERROR_SUBTITLE =
  "We couldn’t find any packages that match your search criteria.";
