import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { AvailabilityErrorModal } from "./component";
import { IStoreState } from "../../../../reducers/types";
import { PackagesAvailabilityCallState } from "../../reducer/state";
import {
  getPackagesAvailabilityCallState,
  getPackagesAvailabilityErrors,
} from "../../reducer";
import { fetchInitialPackagesAvailability } from "../../actions/actions";
import {
  getHasSetFareClassFilter,
  getHasSetStopsOption,
} from "../../../search/reducer";

const mapStateToProps = (state: IStoreState) => {
  return {
    hasFailed:
      getPackagesAvailabilityCallState(state) ===
      PackagesAvailabilityCallState.Failed,
    errors: getPackagesAvailabilityErrors(state),
    hasSetStopsOption: getHasSetStopsOption(state),
    hasSetFareClass: getHasSetFareClassFilter(state),
  };
};

const mapDispatchToProps = {
  fetchInitialPackagesAvailability,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type AvailabilityErrorModalConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedAvailabilityErrorModal = withRouter(
  connector(AvailabilityErrorModal)
);
