import React from "react";
import { Box } from "@material-ui/core";
import { RouteComponentProps } from "react-router-dom";
import "./styles.scss";
import { MobilePackagesBookWorkflowConnectorProps } from "./container";
import {
  CardPaymentSelectors,
  getChildState,
  getParentState,
  ParentState,
  RewardsPaymentEventTypes,
  useCheckoutState,
  useCheckoutStateSelector,
} from "@capone/checkout";
import { Event, TEvent } from "../../state/events";
import { PackagesMachineContext } from "../../state/types";
import { RewardsAccount } from "redmond";
import { PATH_BOOK_CONFIRMATION } from "../../../../utils/paths";
import { FlightBookPassengerSelection } from "../FlightBookPassengerSelection";
import { ActionButton, B2BSpinner, BackButton, LoadingPopup } from "halifax";
import { getLoadingMessage } from "./utils";
import {
  PackagesContactInfo,
  PackagesPriceBreakdown,
  PackagesShopProgressBar,
  PackageTreesCard,
} from "../";
import { BookingInProgressModal } from "../BookingInProgressModal";
import { BookingErrorModal } from "../BookingErrorModal";
import { HotelBookPassengerSelection } from "../HotelBookPassengerSelection";
import { SeatSelection } from "../SeatSelection";
import { PackagesRewardsAndPayment } from "../RewardsAndPayment";
import { MobileTripReview } from "../MobileTripReview";
import { CONFIRM_AND_BOOK_CTA_TEXT } from "../textConstants";
import { goToHotelShop } from "../../../hotel-shop/utils/queryStringHelpers";

export interface IMobilePackagesBookWorkflowProps
  extends MobilePackagesBookWorkflowConnectorProps,
    RouteComponentProps {}

export const MobilePackagesBookWorkflow = ({
  history,
  finalizePackageResponse,
  selectedLodging,
  selectedTrip,
  tripDetails,
  selectedRoom,
  hotelFromDate,
  hotelUntilDate,
  packagePricing,
  rewardsAccounts,
  searchedAdultsCount,
  searchedChildren,
  searchedInfants,
  searchedDestination,
  searchedOrigin,
  searchedDepartureDate,
  searchedReturnDate,
}: IMobilePackagesBookWorkflowProps) => {
  const breadcrumbsRef = React.useRef<HTMLDivElement>(null);

  const [state, send] = useCheckoutState<TEvent, PackagesMachineContext>();

  const parentState = getParentState(state.value) as ParentState;
  const childState = getChildState(state.value);

  const isCostCoveredBySelectedPaymentMethods = useCheckoutStateSelector(
    CardPaymentSelectors.getIsCostCoveredBySelectedPaymentMethods
  );

  const setRewardsAccounts = (accounts: RewardsAccount[]) =>
    send({
      type: RewardsPaymentEventTypes.SET_REWARDS_ACCOUNTS,
      accounts,
    });

  const handleBack = () => {
    send(Event.GO_TO_CARD_PAYMENT);
  };

  const handleContinue = () => {
    send(Event.NEXT);
  };

  // initialize checkout context from redux
  React.useEffect(() => {
    if (
      selectedLodging &&
      selectedRoom &&
      selectedTrip &&
      finalizePackageResponse &&
      tripDetails &&
      hotelFromDate &&
      hotelUntilDate &&
      packagePricing &&
      searchedDestination &&
      searchedOrigin &&
      searchedDepartureDate
    ) {
      send({
        type: Event.INITIALIZE_CHECKOUT_STATE,
        payload: {
          selectedLodging,
          selectedRoom,
          selectedTrip,
          finalizePackageResponse,
          tripDetails,
          hotelFromDate,
          hotelUntilDate,
          packagePricing,
          searchedAdultsCount,
          searchedChildren,
          searchedInfants: searchedInfants.map((infant) => infant.age),
          searchedDestination,
          searchedDepartureDate,
          searchedReturnDate,
          searchedOrigin,
        },
      });
    } else {
      goToHotelShop({ history });
    }
  }, [
    selectedLodging,
    selectedRoom,
    selectedTrip,
    finalizePackageResponse,
    tripDetails,
    hotelFromDate,
    hotelUntilDate,
    packagePricing,
    searchedAdultsCount,
    searchedChildren,
    searchedInfants,
    searchedDestination,
    searchedDepartureDate,
    searchedReturnDate,
  ]);

  React.useEffect(() => {
    // update checkout state with rewards accounts from redux
    setRewardsAccounts(rewardsAccounts);
  }, [rewardsAccounts]);

  React.useEffect(() => {
    // update path so header becomes visible for confirmation
    if (parentState === ParentState.bookingConfirmation) {
      history.replace(PATH_BOOK_CONFIRMATION);
    }
  }, [parentState]);

  React.useEffect(() => {
    send({ type: Event.SET_PLATFORM, platform: "mobile" });
  }, []);

  React.useEffect(() => {
    if (breadcrumbsRef.current && parentState === ParentState.review) {
      // scroll to right of mobile breadcrumbs
      breadcrumbsRef.current.scrollLeft = breadcrumbsRef.current.scrollWidth;
    }
  }, [breadcrumbsRef.current, parentState]);

  React.useEffect(() => {
    if (parentState === ParentState.bookingConfirmation) {
      history.replace(PATH_BOOK_CONFIRMATION);
    }
  }, [parentState]);

  const loadingMessage = getLoadingMessage(
    parentState,
    childState || undefined
  );

  return (
    <Box className="mobile-packages-book-workflow-root">
      {parentState === ParentState.review && (
        <>
          <Box className="packages-book-review-header">
            <BackButton
              className="packages-book-review-header-back-button"
              onClick={handleBack}
            />
            <PackagesPriceBreakdown isMobile dropdown />
          </Box>

          <PackagesShopProgressBar isMobile innerRef={breadcrumbsRef} />

          <MobileTripReview />
          <ActionButton
            onClick={handleContinue}
            message={CONFIRM_AND_BOOK_CTA_TEXT}
            className="packages-mobile-book-button"
            disabled={!isCostCoveredBySelectedPaymentMethods}
          />
          <PackageTreesCard />
        </>
      )}

      {parentState === ParentState.passengerInformation && (
        <FlightBookPassengerSelection isMobile showNonModalContent={false} />
      )}

      {parentState === ParentState.lodgingPassengerInformation && (
        <HotelBookPassengerSelection isMobile showNonModalContent={false} />
      )}

      {parentState === ParentState.contactInformation && (
        <PackagesContactInfo isMobile contactFormOnly />
      )}

      {parentState === ParentState.seatSelection && <SeatSelection isMobile />}

      {parentState === ParentState.cardPayment && (
        <PackagesRewardsAndPayment isMobile />
      )}

      <LoadingPopup
        classes={["mobile-packages-book-loading-popup"]}
        open={!!loadingMessage}
        message={loadingMessage || ""}
        indicator={B2BSpinner}
        indicatorSize="small"
        verticalAlignment="center"
        fullScreen={true}
      />

      <BookingInProgressModal />
      <BookingErrorModal />
    </Box>
  );
};
