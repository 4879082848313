import { put, putResolve, select } from "redux-saga/effects";
import { actions } from "../actions";
import Logger from "../../../utils/logger";
import {
  FetchFlightTripDetailsResponse,
  FlightShopStep,
  PackagedRoom,
} from "redmond";
import { fetchPackagesTripDetails } from "../../../api/v0/flight-shop/fetchPackagesTripDetails";
import { selectedPackageByRateId } from "../../hotel-shop/reducer";
import { IStoreState } from "../../../reducers/types";
import {
  getFlightShopProgress,
  getParsedPackagesByOutboundFareSlice,
  getSelectedTrip,
} from "../reducer";

export default function* fetchPackagesTripDetailsSaga({
  tripId,
}: actions.IFetchPackagesTripDetails) {
  try {
    const state: IStoreState = yield select();
    let selectedPackage: PackagedRoom | undefined =
      yield selectedPackageByRateId(state);

    const shopPackageId = selectedPackage?.packageDetails.opaquePackageToken;

    const flightShopProgress = getFlightShopProgress(state);
    const selectedTrip = getSelectedTrip(state);
    const parsedPackage =
      getParsedPackagesByOutboundFareSlice(state)[
        selectedTrip?.outgoingFareSliceId || ""
      ];

    if (flightShopProgress === FlightShopStep.ChooseReturn) {
      if (!selectedTrip) {
        throw new Error("Selected trip must be present.");
      }
      if (!selectedTrip?.outgoingFareSliceId) {
        throw new Error(
          "outgoing fare slice on selected trip must be present."
        );
      }
      if (!parsedPackage) {
        throw new Error("parsedPackage must be present.");
      }
    } else if (!shopPackageId) {
      throw new Error("Shop Package Id must be present.");
    }

    const opaqueToken =
      flightShopProgress === FlightShopStep.ChooseReturn
        ? parsedPackage.opaquePackageToken
        : shopPackageId!;

    const tripDetailsResults: FetchFlightTripDetailsResponse =
      yield fetchPackagesTripDetails(tripId, opaqueToken);

    yield putResolve(actions.setPackagesTripDetails(tripDetailsResults));
  } catch (e) {
    Logger.debug(e);
    yield put(actions.setPackagesTripDetailsCallStateFailed(e));
  }
}
