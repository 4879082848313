import React from "react";
import {
  TravelerSelectWorkflow,
  TravelerSelectStep,
  ActionButton,
  isAdult,
} from "halifax";
import { PersonId, SELECT_TRAVELERS } from "redmond";
import clsx from "clsx";

import "./styles.scss";
import { HotelBookPassengerSelectionConnectorProps } from "./container";
import * as constants from "./textConstants";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";
import { config } from "../../../../api/config";
import {
  FlightPassengerSelectors,
  ParentState,
  useCheckoutState,
  useCheckoutStateSelector,
  useChildMachineSelector,
} from "@capone/checkout";
import { LodgingSelectors } from "../../state/selectors";
import { personToIPerson } from "../../../../utils/personToIPerson";
import { Event, TEvent } from "../../state/events";
import { PackagesMachineContext } from "../../state/types";
import { Box, Typography } from "@material-ui/core";
import { PackagesPriceBreakdown } from "../PriceBreakdown";
import { DesktopPackageBookValidationError } from "../DesktopPackagesBookWorkflow";

export interface IHotelBookPassengerSelectionProps
  extends HotelBookPassengerSelectionConnectorProps {
  isMobile?: boolean;
  validationErrorTypes?: DesktopPackageBookValidationError[];
  setValidationErrorTypes?: (
    types: DesktopPackageBookValidationError[]
  ) => void;

  showNonModalContent?: boolean;
}

export const HotelBookPassengerSelection = ({
  isMobile,
  validationErrorTypes,
  setValidationErrorTypes,

  showNonModalContent,
}: IHotelBookPassengerSelectionProps) => {
  const [_state, send] = useCheckoutState<TEvent, PackagesMachineContext>();

  const travelers = useChildMachineSelector(
    ParentState.passengerInformation,
    FlightPassengerSelectors.getUserPassengers
  );

  const selectedFlightTravelerIds = useCheckoutStateSelector(
    FlightPassengerSelectors.getAllSelectedPassengerIdsParent
  );
  const selectedTravelerId = useCheckoutStateSelector(
    LodgingSelectors.getSelectedPassengerIdParent
  );

  const selectablePassengers = React.useMemo(
    () =>
      travelers
        ?.filter(
          (person) =>
            isAdult(person.dateOfBirth) &&
            selectedFlightTravelerIds?.includes(person.id)
        )
        .map(personToIPerson),
    [travelers, selectedFlightTravelerIds]
  );

  const handleSelectPassenger = (passengerId?: PersonId) => {
    send({
      type: Event.SELECT_PASSENGER,
      passengerId,
      singleTravelerWorkflow: true,
    });
  };

  const canContinue = isMobile || !!selectedTravelerId;

  const handleContinue = () => {
    if (canContinue) {
      setValidationErrorTypes?.([]);
      send(Event.NEXT);
    } else {
      setValidationErrorTypes?.(["hotel-travelers"]);
    }
  };

  const handleBack = () => {
    send(Event.PREVIOUS);
  };

  React.useEffect(() => {
    if (!selectedTravelerId && selectablePassengers.length === 1) {
      handleSelectPassenger(selectablePassengers[0].id);
    }
  }, [selectedTravelerId, selectablePassengers]);

  React.useEffect(() => {
    if (selectedTravelerId && selectablePassengers.length) {
      // reset hotel passenger if not in selected flight passengers
      if (
        !selectablePassengers.some(
          (passenger) => passenger.id === selectedTravelerId
        )
      ) {
        handleSelectPassenger(undefined);
      }
    }
  }, [selectablePassengers, selectedTravelerId]);

  React.useEffect(() => {
    if (canContinue) {
      setValidationErrorTypes?.([]);
    }
  }, [canContinue]);

  return (
    <>
      <TravelerSelectWorkflow
        readOnly
        hideTravelerActions
        displayModalSubtitle
        showGenderField
        showNationalityField
        singleTravelerWorkflow
        buttonClassName="b2b"
        className={clsx("packages-hotel-book-passenger-selection-root", {
          error: validationErrorTypes?.includes("hotel-travelers"),
        })}
        errorMessage={constants.ADD_TRAVELER_ERROR_MESSAGE}
        isMobile={isMobile}
        progress={
          isMobile ? TravelerSelectStep.TravelerSelect : TravelerSelectStep.Main
        }
        setProgress={() => {}}
        selectedTravelerIds={selectedTravelerId ? [selectedTravelerId] : []}
        setSelectedTravelerIds={(travelerIds: string[]) => {
          trackEvent({
            eventName: SELECT_TRAVELERS,
            properties: {},
          });
          handleSelectPassenger(travelerIds[0]);
        }}
        titles={{
          travelerInfoTitle: isMobile
            ? constants.TRAVELER_INFO_TEXT
            : constants.TRAVELER_INFO_TITLE_SUBHEADING,
          travelerInfoSubtitle: isMobile
            ? constants.TRAVELER_INFO_TITLE_SUBHEADING
            : constants.TRAVELER_INFO_SUBTITLE,
          frequentFlyerTitle: constants.FREQUENT_FLYER_TITLE,
          additionalInfoTitle: constants.ADDITIONAL_INFO_TITLE,
          adultTitle: constants.ADULT_TITLE,
          childTitle: constants.CHILD_TITLE,
          infantSeatTitle: constants.INFANT_SEAT_TITLE,
          infantLapTitle: constants.INFANT_LAP_TITLE,
          addTravelers: isMobile
            ? constants.TRAVELER_INFO_TEXT
            : constants.TRAVELER_INFO_TITLE_SUBHEADING,
          completeTravelerProfileTitle: constants.COMPLETE_PROFILE_TITLE,
          completeTravelerProfileSubtitle: constants.COMPLETE_PROFILE_SUBTITLE,
        }}
        travelers={selectablePassengers}
        tenant={config.TENANT}
        trackEvent={trackEvent}
        onContinue={handleContinue}
        onGoBack={handleBack}
        mobileTravelerRowType="checkbox"
        selectionScreenHeaderElement={
          <PackagesPriceBreakdown isMobile dropdown />
        }
        customHeader={
          <Box className="packages-hotel-book-passenger-mobile-header">
            <Typography variant="h3" className="pax-header-primary-title">
              {constants.WHOS_CHECKING_IN_TITLE}
            </Typography>
            <hr />
            <Typography variant="h4" className="pax-header-secondary-title">
              {constants.TRAVELER_INFO_TITLE_SUBHEADING}
            </Typography>
            <Typography className="pax-header-subtitle">
              {constants.TRAVELER_INFO_SUBTITLE}
            </Typography>
          </Box>
        }
        showMobilePopoverTransition={false}
        bypassSelectForSingleTraveler={false}
        showNonModalContent={showNonModalContent}
      />
      {!isMobile && (
        <>
          <Typography
            className={clsx("packages-hotel-pax-select-notice", {
              error: validationErrorTypes?.includes("hotel-travelers"),
            })}
          >
            {constants.HOTEL_TRAVELER_NOTICE}
          </Typography>
          <ActionButton
            onClick={handleContinue}
            message={constants.CONTINUE_TEXT}
            className="packages-continue-button"
          />
        </>
      )}
    </>
  );
};
