import { types } from "halifax";
import {
  CallState,
  FareclassOptionFilter,
  SearchPackagesRequestEnum,
  SearchPackagesResponseEnum,
  SearchPackagesResponseInitial,
} from "redmond";
import { actionTypes, actions } from "../actions";
import {
  PackagesHotelsAvailabilitySortOption,
  IHotelFilterState,
  IPackagesAvailabilityState,
  PackagesAvailabilityCallState,
} from "./state";
import { SliceStopCountFilter } from "redmond/build";

export const initialFilterState: IHotelFilterState = {
  isInPolicy: false,
  amenities: [],
  starRatings: [],
  maxPrice: 0,
  hotelName: "",
  freeCancel: false,
  loyaltyPrograms: [],
};

export const initialFareclassOptionFilter: FareclassOptionFilter = {
  basic: false,
  standard: false,
  enhanced: false,
  premium: false,
  luxury: false,
};

export const initialState: IPackagesAvailabilityState = {
  availabilityResponse: undefined,
  packagesAvailabilityCallState: PackagesAvailabilityCallState.NotCalled,
  mapBound: null,
  searchLocation: null,
  searchLodgingIds: null,
  totalPropertyCount: null,
  searchFromDate: null,
  searchUntilDate: null,
  searchDestinationResult: null,
  searchOriginResult: null,
  lodgingIdInFocus: null,
  lodgingIdHovered: null,
  hasViewedUnavailableHotel: false,
  sortOption: PackagesHotelsAvailabilitySortOption.Recommended,
  openDatesModal: false,
  selectedLodgingIndex: null,
  searchAdultsCount: 1,
  searchChildren: [],
  searchInfants: [],
  searchRoomsCount: 1,
  fareclassOptionFilter: initialFareclassOptionFilter,
  stopsOption: SliceStopCountFilter.ANY_NUMBER,
  viewHotelsNearLocation: null,
  viewHotelsNearLocationCategories: [],
  viewHotelsNearLocationCategoriesLoading: false,
  userHotelPreferences: undefined,
  userHotelPreferencesCallState: CallState.NotCalled,
  paymentMethods: [],
  listPaymentMethodCallState: CallState.NotCalled,
  ...initialFilterState,
};

export const reducer = types.exhaustiveReducer(
  initialState,
  (state, action: actions.PackagesAvailabilityActions) => {
    switch (action.type) {
      case actionTypes.FETCH_INITIAL_PACKAGES_AVAILABILITY:
        return {
          ...state,
          availabilityResponse:
            action.requestType === SearchPackagesRequestEnum.InitialRequest
              ? undefined
              : state.availabilityResponse,
          packagesAvailabilityCallState:
            action.requestType === SearchPackagesRequestEnum.InitialRequest
              ? PackagesAvailabilityCallState.InitialSearchCallInProcess
              : PackagesAvailabilityCallState.FollowUpSearchCallInProcess,
        };
      case actionTypes.FETCH_MORE_PACKAGES_AVAILABILITY:
        return {
          ...state,
          availabilityResponse:
            action.requestType === SearchPackagesRequestEnum.InitialRequest
              ? undefined
              : state.availabilityResponse,
          packagesAvailabilityCallState:
            action.requestType === SearchPackagesRequestEnum.InitialRequest
              ? PackagesAvailabilityCallState.InitialSearchCallInProcess
              : PackagesAvailabilityCallState.FollowUpSearchCallInProcess,
        };

      case actionTypes.SET_PACKAGES_AVAILABILITY_RESULTS:
        const isInitialRequest =
          action.payload.searchType ===
          SearchPackagesResponseEnum.InitialResponse;
        const isLodgingsEmpty =
          action.payload.lodgingAvailability.value.lodgings.length === 0;
        const hasCompletedRequest = !action.payload.nextPageToken;

        if (isInitialRequest) {
          return {
            ...state,
            availabilityResponse: action.payload,
            searchLocation: isLodgingsEmpty
              ? state.searchLocation
              : action.payload.lodgingAvailability.value.lodgings[0].lodging
                  .location || state.searchLocation,
            packagesAvailabilityCallState: hasCompletedRequest
              ? PackagesAvailabilityCallState.Complete
              : PackagesAvailabilityCallState.InitialSearchCallSuccess,
          };
        }

        return {
          ...state,
          availabilityResponse: {
            ...action.payload,
            lodgingAvailability: {
              ...action.payload.lodgingAvailability,
              value: {
                ...action.payload.lodgingAvailability.value,
                lodgings: [
                  ...(state.availabilityResponse?.lodgingAvailability?.value
                    .lodgings || []),
                  ...action.payload.lodgingAvailability.value.lodgings,
                ],
              },
            },
            packagesByLodgingId: {
              ...state.availabilityResponse?.packagesByLodgingId,
              ...action.payload.packagesByLodgingId,
            },
            benchmarkFlight: {
              ...(state.availabilityResponse as SearchPackagesResponseInitial)
                ?.benchmarkFlight,
            },
            searchToken: {
              ...(state.availabilityResponse as SearchPackagesResponseInitial)
                ?.searchToken,
            },
            // each FollowUpSearch fetches more hotels based on the specified page size;
            // the new lodging result continues from where the previous call ends.
          },
          searchLocation: state.searchLocation,
          packagesAvailabilityCallState: hasCompletedRequest
            ? PackagesAvailabilityCallState.Complete
            : PackagesAvailabilityCallState.FollowUpSearchCallSuccess,
        };
      case actionTypes.SET_PACKAGES_AVAILABILITY_ERRORS:
        return {
          ...state,
          availabilityErrors: action.errors,
        };

      case actionTypes.SET_PACKAGES_AVAILABILITY_CALL_STATE_FAILED:
        return {
          ...state,
          packagesAvailabilityCallState: PackagesAvailabilityCallState.Failed,
        };

      case actionTypes.RESET_PACKAGES_AVAILABILITY_CALL_STATE:
        return {
          ...state,
          packagesAvailabilityCallState:
            initialState.packagesAvailabilityCallState,
        };

      case actionTypes.SET_POLICY_FILTER:
        return {
          ...state,
          isInPolicy: action.isInPolicy,
          filtersBannerMessage: undefined,
        };

      case actionTypes.SET_LOYALTY_PROGRAMS_FILTER:
        return {
          ...state,
          loyaltyPrograms: [...action.loyaltyPrograms],
          filtersBannerMessage: undefined,
        };

      case actionTypes.SET_AMENITIES_FILTER:
        return {
          ...state,
          amenities: [...action.amenities],
          filtersBannerMessage: undefined,
        };

      case actionTypes.SET_STAR_RATINGS_FILTER:
        return {
          ...state,
          starRatings: [...action.starRatings],
          showPremiumStaysOnly: false,
        };

      case actionTypes.SET_MAX_PRICE_FILTER:
        return {
          ...state,
          maxPrice: action.maxPrice,
        };

      case actionTypes.SET_HOTEL_NAME_FILTER:
        return {
          ...state,
          hotelName: action.hotelName,
          filtersBannerMessage: undefined,
        };

      case actionTypes.SET_FREE_CANCEL_FILTER:
        return {
          ...state,
          freeCancel: action.freeCancel,
          filtersBannerMessage: undefined,
        };

      case actionTypes.SET_PACKAGES_HOTELS_SORT_OPTION:
        return {
          ...state,
          sortOption: action.sortOption,
          filtersBannerMessage: undefined,
        };

      case actionTypes.SET_LODGING_ID_IN_FOCUS:
        return {
          ...state,
          lodgingIdInFocus: action.lodgingId,
          lodgingIdHovered: action.lodgingId,
        };

      case actionTypes.SET_LODGING_ID_HOVERED:
        return {
          ...state,
          lodgingIdHovered: action.lodgingId,
        };

      case actionTypes.SET_MAP_BOUND:
        return {
          ...state,
          mapBound: action.mapBound,
        };

      case actionTypes.SET_OPEN_DATES_MODAL:
        return {
          ...state,
          openDatesModal: action.openDatesModal,
        };

      case actionTypes.SET_SEARCHED_DATES:
        return {
          ...state,
          searchFromDate: action.searchedFromDate,
          searchUntilDate: action.searchedUntilDate,
        };

      case actionTypes.SET_SEARCHED_DESTINATION_RESULT:
        return {
          ...state,
          searchDestinationResult: action.searchedDestinationResult,
        };
      case actionTypes.SET_SEARCHED_ORIGIN_RESULT:
        return {
          ...state,
          searchOriginResult: action.searchedOriginResult,
        };

      case actionTypes.SET_HAS_VIEWED_UNAVAILABLE_HOTEL:
        return {
          ...state,
          hasViewedUnavailableHotel: true,
        };

      case actionTypes.SET_SELECTED_LODGING_INDEX:
        return {
          ...state,
          selectedLodgingIndex: action.index,
        };
      case actionTypes.SET_SEARCHED_TRAVELERS:
        const { adultsCount, children, infants } = action.travelers;
        return {
          ...state,
          searchAdultsCount: adultsCount,
          searchChildren: children,
          searchInfants: infants,
        };
      case actionTypes.SET_SEARCHED_ROOMS_COUNT:
        return {
          ...state,
          searchRoomsCount: action.rooms,
        };
      case actionTypes.SET_VIEW_HOTELS_NEAR_LOCATION:
        const { location } = action;

        return {
          ...state,
          viewHotelsNearLocation: location ? { ...location } : null,
          viewHotelsNearLocationCategoriesLoading: false,
        };

      case actionTypes.SET_VIEW_HOTELS_NEAR_LOCATION_CATEGORIES:
        const { categories } = action;
        return {
          ...state,
          viewHotelsNearLocationCategories: categories,
          viewHotelsNearLocationCategoriesLoading: false,
        };

      case actionTypes.FETCH_VIEW_HOTELS_NEAR_LOCATION_CATEGORIES:
        return {
          ...state,
          viewHotelsNearLocationCategories: [],
          viewHotelsNearLocationCategoriesLoading: true,
        };

      case actionTypes.FETCH_USER_HOTEL_PREFERENCES:
        return { ...state, userHotelPreferencesCallState: CallState.InProcess };

      case actionTypes.SET_USER_HOTEL_PREFERENCES:
        return {
          ...state,
          userHotelPreferences: action.hotelPreferences,
          userHotelPreferencesCallState: CallState.Success,
        };

      case actionTypes.SET_USER_HOTEL_PREFERENCES_CALL_STATE_FAILED:
        return { ...state, userHotelPreferencesCallState: CallState.Failed };

      case actionTypes.LIST_PAYMENT_METHODS:
        return {
          ...state,
          listPaymentMethodCallState: CallState.InProcess,
        };

      case actionTypes.SET_PAYMENT_METHODS:
        return {
          ...state,
          paymentMethods: action.paymentMethods,
          listPaymentMethodCallState: CallState.Success,
        };

      case actionTypes.SET_PAYMENT_METHODS_CALL_STATE_FAILED:
        return {
          ...state,
          listPaymentMethodCallState: CallState.Failed,
        };

      case actionTypes.SET_FARECLASS_OPTION_FILTER:
        return {
          ...state,
          fareclassOptionFilter: action.fareclassOptionFilter,
        };

      case actionTypes.SET_STOPS_OPTION:
        return {
          ...state,
          stopsOption: action.stopsOption,
        };

      default:
        return types.ensureExhaustive(action);
    }
  }
);

export * from "./selectors";
