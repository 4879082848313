import React from "react";
import { DesktopPopupModal, ActionLink, ActionButton, IconName } from "halifax";
import { Box, Divider, Typography } from "@material-ui/core";
import {
  AIRLINE_SELECTION_SUBTITLE,
  FILTER_MODAL_TITLES,
  FLIGHT_NUMBER_SELECTION_SUBTITLE,
} from "../textConstants";
import clsx from "clsx";
import { StopsOptionSelection } from "../../FlightShopFilters/components/StopsOptionSelection";

import { AirlineFilterSelection } from "../../FlightShopFilters/components/AirlineFilterSelection";
import {
  SliceStopCountFilter,
  AirlineCode,
  Airport,
  ITimeRange,
  ITripTerminus,
  RegionType,
  IFlightNumberFilter,
} from "redmond";
import { IAirlineOptions, IFlightShopFilterSelector } from "../../../reducer";
import { MaxPriceFilterSelection } from "../../FlightShopFilters/components/MaxPriceFilterSelection";
import { DurationSelection } from "../../FlightShopFilters/components/DurationSelection";
import { DepartureArrivalSelectionDropdown } from "../../FlightShopFilters/components/DepartureArrivalSelectionDropdown";
import { AirportFilterSelection } from "../../FlightShopFilters/components/AirportFilterSelection";
import { FlightNumberFilterSelection } from "../../FlightShopFilters/components/FlightNumberFilterSelection";
import "./styles.scss";

interface IDesktopAllFiltersModalProp {
  openAllFiltersModal: boolean;
  // setOpenAllFiltersModal: (arg: boolean) => void;
  onCloseModal: () => void;
  handleApply: () => void;
  handleReset: () => void;
  readyToReset: boolean;
  optionsChanged: boolean;
  stopsOption: SliceStopCountFilter;
  setStopsOption: (stopsOption: SliceStopCountFilter) => void;
  airlineFilter: AirlineCode[];
  setAirlineFilter: (airlineFilter: AirlineCode[]) => void;
  allAirlines: IAirlineOptions[];
  flightShopFilters: IFlightShopFilterSelector;
  maxPriceFilter: number;
  setMaxPriceFilter: (maxPrice: number) => void;
  durationFilter: number;
  setDurationFilter: (duration: number) => void;
  minDuration: number;
  maxDuration: number;
  filteredFlightCount: number;
  origin: ITripTerminus | null;
  outboundDepartureTimeRange: ITimeRange;
  outboundArrivalTimeRange: ITimeRange;
  returnDepartureTimeRange: ITimeRange;
  returnArrivalTimeRange: ITimeRange;
  setOutboundDepartureTimeRange: (
    outboundDepartureTimeRange: ITimeRange
  ) => void;
  setOutboundArrivalTimeRange: (outboundArrivalTimeRange: ITimeRange) => void;
  setReturnDepartureTimeRange: (returnDepartureTimeRange: ITimeRange) => void;
  setReturnArrivalTimeRange: (returnArrivalTimeRange: ITimeRange) => void;
  // destination: ITripTerminus | null;
  originAirport?: Airport;
  destinationAirport?: Airport;
  airportFilter: string[];
  setAirportFilter: (airportFilter: string[]) => void;
  flightNumberFilter: IFlightNumberFilter[];
  setFlightNumberFilter: (flightNumbers: IFlightNumberFilter[]) => void;
  flightNumberAirlineFilter: string;
  setFlightNumberAirlineFilter: (airline: string) => void;
  departureDateString: string;
}

export const DesktopAllFiltersModal = (props: IDesktopAllFiltersModalProp) => {
  const {
    openAllFiltersModal,
    // setOpenAllFiltersModal,
    onCloseModal,
    handleApply,
    handleReset,
    optionsChanged,
    readyToReset,
    stopsOption,
    setStopsOption,
    airlineFilter,
    setAirlineFilter,
    flightShopFilters,
    maxPriceFilter,
    setMaxPriceFilter,
    durationFilter,
    setDurationFilter,
    minDuration,
    maxDuration,
    filteredFlightCount,
    outboundDepartureTimeRange,
    outboundArrivalTimeRange,
    returnDepartureTimeRange,
    returnArrivalTimeRange,
    setOutboundDepartureTimeRange,
    setOutboundArrivalTimeRange,
    setReturnDepartureTimeRange,
    setReturnArrivalTimeRange,
    origin,
    // destination,
    originAirport,
    // destinationAirport,
    airportFilter,
    setAirportFilter,
    allAirlines,
    flightNumberFilter,
    setFlightNumberFilter,
    flightNumberAirlineFilter,
    setFlightNumberAirlineFilter,
    departureDateString,
  } = props;
  const { airportOptions, flightNumbersByAirline } = flightShopFilters;

  return (
    <DesktopPopupModal
      open={openAllFiltersModal}
      onClose={onCloseModal}
      className={"desktop-pkg-flight-shop-all-filters-modal"}
      bottomButton={
        <Box
          className={
            "desktop-pkg-flight-shop-all-filters-modal-bottom-buttons-container"
          }
        >
          <ActionLink
            className={clsx("reset-filters-link", "filter-button", {
              inactive: !readyToReset,
            })}
            onClick={handleReset}
            content={FILTER_MODAL_TITLES.clearAllFiltersText}
          />
          <ActionButton
            className={clsx("apply-filters-button", "filter-button")}
            onClick={() => {
              handleApply();
              onCloseModal();
            }}
            defaultStyle="h4r-primary"
            disabled={!optionsChanged}
            message={FILTER_MODAL_TITLES.applyFiltersText(0)}
          />
        </Box>
      }
      invisibleBackdrop={false}
    >
      <Box className="desktop-pkg-flight-shop-all-filters-content-root">
        <Typography variant="h2" className="filters-title">
          All filters
        </Typography>{" "}
        <Divider className="filter-divider" />
        <StopsOptionSelection
          stopsOption={stopsOption}
          setStopsOption={setStopsOption}
          showDropdownContentOnly
          title={FILTER_MODAL_TITLES.numOfStopsText}
          icon={IconName.StopsFilterIcon}
          radioLabelPlacement="end"
        />
        <Divider className="filter-divider" />
        <AirlineFilterSelection
          airlineFilter={airlineFilter}
          setAirlineFilter={setAirlineFilter}
          showDropdownContentOnly
          title={FILTER_MODAL_TITLES.airlineText}
          icon={IconName.DiagonalBlueAirplane}
          optionLabelPlacement="start"
          flightShopFilters={flightShopFilters}
        />{" "}
        <Divider className="filter-divider" />{" "}
        <DepartureArrivalSelectionDropdown
          outboundDepartureTimeRange={outboundDepartureTimeRange}
          outboundArrivalTimeRange={outboundArrivalTimeRange}
          returnDepartureTimeRange={returnDepartureTimeRange}
          returnArrivalTimeRange={returnArrivalTimeRange}
          setOutboundDepartureTimeRange={setOutboundDepartureTimeRange}
          setOutboundArrivalTimeRange={setOutboundArrivalTimeRange}
          setReturnDepartureTimeRange={setReturnDepartureTimeRange}
          setReturnArrivalTimeRange={setReturnArrivalTimeRange}
          showDropdownContentOnly
          icon={IconName.ClockIconThin}
          outboundTitle={FILTER_MODAL_TITLES.outboundTimesText}
          returnTitle={FILTER_MODAL_TITLES.returnTimesText}
          origin={origin}
          // destination={destination}
          originAirport={originAirport}
          // destinationAirport={destinationAirport}
          showDivider
        />
        <Divider className="filter-divider" />
        <MaxPriceFilterSelection
          flightShopFilters={flightShopFilters}
          maxPriceFilter={maxPriceFilter}
          setMaxPriceFilter={setMaxPriceFilter}
          title={FILTER_MODAL_TITLES.priceRangeText}
          subtitle={FILTER_MODAL_TITLES.priceRangeSubtitleText}
          icon={IconName.MoneyOutlineThin}
          showDropdownContentOnly
        />
        <Divider className="filter-divider" />
        <DurationSelection
          durationFilter={durationFilter}
          setDurationFilter={setDurationFilter}
          minDuration={minDuration}
          maxDuration={maxDuration}
          flightCount={filteredFlightCount}
          showDropdownContentOnly
        />
        {origin?.id.code.regionType !== RegionType.Airport && (
          <>
            <Divider className="filter-divider" />
            <AirportFilterSelection
              allAirports={airportOptions}
              airportFilter={airportFilter}
              setAirportFilter={setAirportFilter}
              showDropdownContentOnly
              title={FILTER_MODAL_TITLES.outboundAirportText}
              icon={IconName.DiagonalBlueAirplane}
              optionLabelPlacement="start"
              displayLabelAndValue
            />
          </>
        )}
        <Divider className="filter-divider" />
        <FlightNumberFilterSelection
          showDropdownContentOnly
          allAirlines={allAirlines}
          flightNumbersByAirline={flightNumbersByAirline}
          flightNumberFilter={flightNumberFilter}
          departureDateString={departureDateString}
          setFlightNumberFilter={setFlightNumberFilter}
          useAirlineCheckbox
          title={FILTER_MODAL_TITLES.flightNumberText}
          icon={IconName.DiagonalBlueAirplane}
          flightNumberAirlineFilter={flightNumberAirlineFilter}
          setFlightNumberAirlineFilter={setFlightNumberAirlineFilter}
          airlineSubtitle={AIRLINE_SELECTION_SUBTITLE}
          flightNumberSubtitle={FLIGHT_NUMBER_SELECTION_SUBTITLE}
        />
      </Box>
    </DesktopPopupModal>
  );
};
