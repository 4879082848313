import {
  getClickCancelOOPModalEvent,
  getClickContinueOOPModalEvent,
  getShowOOPModalEvent,
  isCorpTenant,
} from "@capone/common";
import { Typography } from "@material-ui/core";
import clsx from "clsx";
import {
  HotelShopRoomTypePickerEnum,
  HotelShopRoomTypePickerVariant,
  Icon,
  IconName,
  PackageHotelShopRoomTypePicker,
  TrackingEventControlType,
} from "halifax";
import React, { useContext, useEffect, useRef, useState } from "react";
import { RouteComponentProps } from "react-router";
import {
  ModalScreens,
  POLICY_DESCRIPTOR,
  PolicyViolation,
  RoomInfoProductsWithTransformedIndexes,
  SELECT_PACKAGE_HOTEL_ROOM,
  VIEWED_POLICY_DESCRIPTOR,
  ViewedCorpRateDescriptorEntryPoints,
} from "redmond";
import { ClientContext } from "../../../../App";
import { config } from "../../../../api/config";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";

import { DesktopRoomPickerConnectorProps } from "./container";
import { onOpenCompareBarTooltip } from "../../../../utils/events";
import "./styles.scss";
import { getEarnTagText } from "../../textConstants";

export interface IDesktopRoomPickerProps
  extends DesktopRoomPickerConnectorProps,
    RouteComponentProps {
  roomInfoProductsType?: HotelShopRoomTypePickerVariant;
  handleReadyToRedirect?: (
    trackingEventControl?: TrackingEventControlType
  ) => void;
  setRoomInfoProduct?: (
    roomInfoProduct: RoomInfoProductsWithTransformedIndexes
  ) => void;
  setRoomProductIndex?: (roomProductIndex: number) => void;
  nonRefundablePolicyOverrideText?: string;
}

export const DesktopRoomPicker = (props: IDesktopRoomPickerProps) => {
  const {
    checkinDate,
    checkoutDate,
    // history,
    // fetchHotelShop,
    adultsCount,
    children,
    largestValueAccount,
    handleReadyToRedirect,
    roomInfoProductsType,
    setRoomInfoProduct,
    setRoomProductIndex,
    canEarnRewards,
  } = props;
  const isFirstUpdate = useRef<boolean>(true);
  const [corpPolicyDescriptorViewCount, setCorpPolicyDescriptorViewCount] =
    useState(0);

  const { isAutoApprovalEnabled, policies } = useContext(ClientContext);

  const modalType = isAutoApprovalEnabled
    ? "out_of_policy_auto"
    : "out_of_policy";

  const showEarnEnhancement =
    !!largestValueAccount &&
    !!largestValueAccount.earn.hotelsMultiplier &&
    canEarnRewards;

  useEffect(() => {
    if (checkinDate && checkoutDate && (adultsCount || children)) {
      // skip the first update
      if (isFirstUpdate.current) {
        isFirstUpdate.current = false;
      }
    }
  }, [checkinDate, checkoutDate, adultsCount, children]);

  const onOpenPolicyDescriptor = (
    entryPoint: string,
    reasons: PolicyViolation[]
  ) => {
    if (corpPolicyDescriptorViewCount <= 5) {
      trackEvent({
        eventName: VIEWED_POLICY_DESCRIPTOR,
        properties: {
          type: POLICY_DESCRIPTOR,
          entry_point: entryPoint,
          funnel: "packages",
          policy_reason: reasons.join(", "),
        },
      });
      setCorpPolicyDescriptorViewCount((prevState) => prevState + 1);
    }
  };

  return (
    <PackageHotelShopRoomTypePicker
      {...props}
      roomInfoProductsType={
        roomInfoProductsType ?? {
          roomInfoProducts: props.roomInfoProducts,
          variant: HotelShopRoomTypePickerEnum.Default,
        }
      }
      onClickContinue={(
        room_type?: string,
        in_policy?: boolean,
        hotel_loyalty_eligible?: boolean
      ) => {
        if (handleReadyToRedirect) {
          handleReadyToRedirect({
            [SELECT_PACKAGE_HOTEL_ROOM]: {
              properties: {
                room_type,
                ...(isCorpTenant(config.TENANT) && {
                  in_policy,
                  hotel_loyalty_eligible,
                }),
              },
            },
          });
        }
      }}
      className={clsx("b2b", "desktop-room-picker")}
      reserveButtonClassName={"reserve-cta"}
      desktopReserveButtonText={<span>{`Continue for `}</span>}
      earnTagContent={
        showEarnEnhancement ? (
          <>
            <Icon name={IconName.StarIcon} />
            <Typography
              className="earn-tag-text"
              dangerouslySetInnerHTML={{
                __html: getEarnTagText(
                  largestValueAccount.earn.hotelsMultiplier,
                  largestValueAccount.rewardsBalance.currencyDescription ??
                    largestValueAccount.rewardsBalance.currency
                ),
              }}
            />
          </>
        ) : undefined
      }
      earnTagClassName={showEarnEnhancement ? "b2b" : undefined}
      setRoomInfoProduct={setRoomInfoProduct}
      setRoomProductIndex={setRoomProductIndex}
      policyLimit={policies?.hotels.policies[0].maxPricePerNight}
      isApprovalRequired={!isAutoApprovalEnabled}
      onShowOutOfPolicyModal={() =>
        trackEvent(
          getShowOOPModalEvent(ModalScreens.HOTELS_SHOP, "packages", modalType)
        )
      }
      onClickOutOfPolicyContinue={() =>
        trackEvent(
          getClickContinueOOPModalEvent(
            ModalScreens.HOTELS_SHOP,
            "packages",
            modalType
          )
        )
      }
      onClickOutOfPolicyCancel={() =>
        trackEvent(
          getClickCancelOOPModalEvent(
            ModalScreens.HOTELS_SHOP,
            "packages",
            modalType
          )
        )
      }
      onOpenPolicyDescriptor={onOpenPolicyDescriptor}
      onViewCorpCompareBar={onOpenCompareBarTooltip(
        ViewedCorpRateDescriptorEntryPoints.HOTELS_ROOM_DETAILS
      )}
    />
  );
};
