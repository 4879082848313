import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { BookingErrorModal } from "./component";
import { IStoreState } from "../../../../reducers/types";
import {
  getDepartureDate,
  getFareclassOptionFilter,
  getOrigin,
  getReturnDate,
  getStopsOption,
  getTravelers,
} from "../../../search/reducer";
import { getPackageSelectedLodging } from "../../../hotel-shop/reducer";
import { setPackagesFlightShopProgress } from "../../../flight-shop/actions/actions";

const mapStateToProps = (state: IStoreState) => ({
  selectedLodging: getPackageSelectedLodging(state),
  departureDate: getDepartureDate(state),
  returnDate: getReturnDate(state),
  travelers: getTravelers(state),
  fareClassOptionFilter: getFareclassOptionFilter(state),
  origin: getOrigin(state),
  stopsOption: getStopsOption(state),
});

const mapDispatchToProps = {
  setPackagesFlightShopProgress,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type BookingErrorModalConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedBookingErrorModal = withRouter(
  connector(BookingErrorModal)
);
