import axios from "axios";
import { config } from "../../config";

import { SearchPackagesRequest, SearchPackagesResponse } from "redmond";
import { flightLodgingSearchApiPrefix } from "../paths";

export const fetchPackagesAvailability = (
  body: SearchPackagesRequest
): Promise<SearchPackagesResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await axios.post(flightLodgingSearchApiPrefix, body, {
        baseURL: config.baseURL,
      });

      resolve(res.data);
    } catch (e) {
      reject(e);
    }
  });
