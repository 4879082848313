import { Box } from "@material-ui/core";
import React from "react";
import { AirlineFilterSelection } from "./components/AirlineFilterSelection";
import { DepartureArrivalSelectionDropdown } from "./components/DepartureArrivalSelectionDropdown";
import { DurationSelection } from "./components/DurationSelection";
import { StopsOptionSelection } from "./components/StopsOptionSelection";
import { MaxPriceFilterSelection } from "./components/MaxPriceFilterSelection";
import "./styles.scss";
import { RouteComponentProps } from "react-router-dom";
import { FlightShopFiltersConnectorProps } from "./container";
import { useFilterLabelValues } from "./useFilterLabelValues";
import { FILTER_MODAL_TITLES } from "../FlightShopAllFiltersModal/textConstants";
import { IconName } from "halifax";

interface IFlightShopFilters
  extends FlightShopFiltersConnectorProps,
    RouteComponentProps {}

export const FlightShopFilters = (props: IFlightShopFilters) => {
  const {
    stopsOption,
    hasSetStopsOption,
    setStopsOption,
    airlineFilter,
    setAirlineFilter,
    hasSetAirlineFilter,
    flightShopFilters,
    outboundDepartureTimeRange,
    outboundArrivalTimeRange,
    returnDepartureTimeRange,
    returnArrivalTimeRange,
    setOutboundDepartureTimeRange,
    setOutboundArrivalTimeRange,
    setReturnDepartureTimeRange,
    setReturnArrivalTimeRange,
    durationFilter,
    minDuration,
    maxDuration,
    flightCount,
    setDurationFilter,
    maxPriceFilter,
    setMaxPriceFilter,
    hasSetOutboundTimeRange,
    hasSetReturnTimeRange,
    fareclassFilter,
    hasSetTimeRange,
    hasSetMaxPriceFilter,
  } = props;

  const filterLabelValues = useFilterLabelValues({
    stopsOption,
    airlineFilter,
    hasSetOutboundTimeRange,
    hasSetReturnTimeRange,
    maxPriceFilter,
    airlines: flightShopFilters.airlineOptions,
    fareclassFilter,
    durationFilter,
  });

  return (
    <Box className="packages-flight-shop-filters">
      <StopsOptionSelection
        stopsOption={stopsOption}
        hasSetStopsOption={hasSetStopsOption}
        setStopsOption={setStopsOption}
        appliedLabel={filterLabelValues.stopsLabel}
      />
      <AirlineFilterSelection
        airlineFilter={airlineFilter}
        setAirlineFilter={setAirlineFilter}
        hasSetAirlineFilter={hasSetAirlineFilter}
        flightShopFilters={flightShopFilters}
        appliedLabel={filterLabelValues?.airlineLabel}
      />
      <DepartureArrivalSelectionDropdown
        outboundDepartureTimeRange={outboundDepartureTimeRange}
        outboundArrivalTimeRange={outboundArrivalTimeRange}
        returnDepartureTimeRange={returnDepartureTimeRange}
        returnArrivalTimeRange={returnArrivalTimeRange}
        setOutboundDepartureTimeRange={setOutboundDepartureTimeRange}
        setOutboundArrivalTimeRange={setOutboundArrivalTimeRange}
        setReturnDepartureTimeRange={setReturnDepartureTimeRange}
        setReturnArrivalTimeRange={setReturnArrivalTimeRange}
        appliedLabel={filterLabelValues?.timeLabel}
        hasSetTimeRange={hasSetTimeRange}
        icon={IconName.ClockIconThin}
        outboundTitle={FILTER_MODAL_TITLES.outboundTimesText}
        returnTitle={FILTER_MODAL_TITLES.returnTimesText}
      />
      <DurationSelection
        durationFilter={durationFilter}
        minDuration={minDuration}
        maxDuration={maxDuration}
        flightCount={flightCount}
        setDurationFilter={setDurationFilter}
        appliedLabel={filterLabelValues?.durationLabel}
      />
      <MaxPriceFilterSelection
        maxPriceFilter={maxPriceFilter}
        setMaxPriceFilter={setMaxPriceFilter}
        flightShopFilters={flightShopFilters}
        appliedLabel={filterLabelValues.priceLabel}
        hasSetMaxPrice={hasSetMaxPriceFilter}
      />
    </Box>
  );
};
