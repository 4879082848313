import { Box } from "@material-ui/core";
import React, { useEffect } from "react";
import { PackagesFlightShopConnectorProps } from "./container";
import { RouteComponentProps } from "react-router-dom";
import {
  B2BLoadingPopup,
  FlightSearchLoadingImage,
  useDeviceTypes,
} from "halifax";
import { DesktopFlightShop } from "./components/DesktopFlightShop";
import { MobileFlightShop } from "./components/MobileFlightShop";
import { EARN_ENHANCEMENT_SUBTITLE } from "./textConstants";
import { SEARCHING_FOR_FLIGHTS } from "./components/FlightList/components/FlightListInfo/textConstants";
import { PackagesAvailabilityCallState } from "../availability/reducer/state";
import { goToHotelShop } from "../hotel-shop/utils/queryStringHelpers";
import { FlightShopStep } from "redmond";

export interface IPackagesFlightShopProps
  extends PackagesFlightShopConnectorProps,
    RouteComponentProps {}

export const PackagesFlightShop = (props: IPackagesFlightShopProps) => {
  const {
    largestValueAccount,
    isFlightShopLoading,
    fetchPackagesFlightShop,
    history,
    packagesAvailabilityCallState,
    flightShopProgress,
    setPackagesFlightShopProgress,
  } = props;
  const { matchesDesktop, matchesMobile } = useDeviceTypes();

  useEffect(() => {
    window.scrollTo(0, 0);

    if (flightShopProgress === FlightShopStep.ReviewItinerary) {
      // if we're navigating from itinerary review (via breadcrumbs or browser back button)
      // make sure we're on the return step to prevent buggy flight list behavior
      setPackagesFlightShopProgress(FlightShopStep.ChooseReturn);
    }

    fetchPackagesFlightShop(history);
  }, []);

  useEffect(() => {
    // If packages availability state is not there, redirect back to packages
    if (
      packagesAvailabilityCallState === PackagesAvailabilityCallState.NotCalled
    ) {
      goToHotelShop({ history });
    }
  }, [packagesAvailabilityCallState]);

  return (
    <Box className={"packages-flight-shop-root"}>
      {isFlightShopLoading && (
        <B2BLoadingPopup
          open={isFlightShopLoading}
          message={SEARCHING_FOR_FLIGHTS}
          secondaryMessage={EARN_ENHANCEMENT_SUBTITLE(
            largestValueAccount.earn.flightsMultiplier,
            largestValueAccount.productDisplayName
          )}
          image={FlightSearchLoadingImage ?? ""}
          className="flight-list-loading-popup"
          popupSize={matchesMobile ? "mobile" : "desktop"}
        />
      )}
      {matchesMobile && <MobileFlightShop />}
      {matchesDesktop && <DesktopFlightShop />}
    </Box>
  );
};
