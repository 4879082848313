import React, { useState, useRef, useEffect } from "react";
import { IDesktopShopProps } from "./container";
import { Box, Typography } from "@material-ui/core";
import {
  HotelCarousel,
  HotelAddress,
  HotelAmenities,
  HotelReviewBadge,
  HotelShopChooseRoomSkeleton,
  HotelTripAdvisorReviews,
  HotelDescription,
} from "halifax";
import clsx from "clsx";
import { DesktopHotelPhotoModal } from "../DesktopHotelPhotoModal";
import * as textConstants from "./textConstants";
import { HotelShopSmallMap } from "../HotelShopSmallMap/component";
import { DesktopHotelShopChooseRoom } from "../DesktopHotelShopChooseRoom";
import "./styles.scss";
import { DesktopRoomPicker } from "../DesktopRoomPicker";
import { HotelShopExpandedMap } from "../HotelShopExpandedMap";
import { DesktopRewardsHeader } from "../DesktopRewardsHeader";
import { PackagesShopProgressBar } from "../../../book/components";
import { ShopErrorModal } from "../ShopErrorModal";
import { goToAvailability } from "../../utils/queryStringHelpers";
import { PackageHotelShopCallState } from "../../reducer/state";
import { VIEWED_PACKAGE_HOTEL_SHOP_MAP } from "redmond";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";

export const DesktopShop = (props: IDesktopShopProps) => {
  const {
    hotelShopCallInProgress,
    hotelShopCallState,
    lodging,
    fromDate,
    untilDate,
    roomInfoProductsType,
    handleReadyToRedirect,
    setRoomInfoProduct,
    setRoomProductIndex,
    hasFailed,
    history,
    origin,
    adultsCount,
    children,
    infants,
    stopsOption,
    fareClassOptionFilter,
    searchDistanceFromShopProperties,
    packagesByLodgingId,
  } = props;
  const [showExpandedHotelMap, setShowExpandedHotelMap] = useState(false);

  const ref = useRef<HTMLDivElement>(null);

  const renderSkeleton = () => (
    <Box className="desktop-hotel-shop-skeleton">
      <HotelCarousel isSkeleton />
      <Box className="content-container">
        {!lodging && (
          <Box className="section-wrapper-container">
            <Box className={clsx("section-wrapper")}>
              <HotelAddress isSkeleton />
              <HotelAmenities isSkeleton />
              <HotelReviewBadge isSkeleton />
            </Box>
            <Box className={clsx("section-wrapper")}>
              <HotelReviewBadge isSkeleton />
              <HotelReviewBadge isSkeleton />
              <HotelAmenities isSkeleton />
            </Box>
            <Box className={clsx("section-wrapper")}>
              <HotelAmenities isSkeleton />
              <HotelAddress isSkeleton />
              <HotelAmenities isSkeleton />
            </Box>
          </Box>
        )}

        <HotelShopChooseRoomSkeleton />
      </Box>
    </Box>
  );

  const renderAvailability = () => {
    const { lodging: lodgingData } = lodging || {};

    if (!lodging) {
      return null;
    }

    return (
      <Box className={clsx("desktop-hotel-shop-container")}>
        <DesktopHotelPhotoModal />
        <Box className={clsx("hotel-shop-right-column")}>
          <DesktopHotelShopChooseRoom />
        </Box>
        {lodgingData?.amenities && lodgingData?.amenities.length > 0 && (
          <Box className={clsx("section-wrapper")}>
            <Typography variant="h2" className="hotel-amenities-title">
              Hotel Amenities
            </Typography>
            <HotelAmenities
              amenities={lodgingData?.amenities}
              displayCount={8}
              showAmenitiesText={`More Amenities (${
                (lodgingData?.amenities.length ?? 8) - 8
              })`}
              hideAmenityIconsInTooltip
            />
          </Box>
        )}
        <Box className={clsx("section-wrapper")}>
          <Typography variant="h2">About the Property</Typography>
          <HotelDescription
            description={lodging?.lodging?.description}
            onMapClick={() => {
              setShowExpandedHotelMap(true);

              const packagePricingDetails =
                packagesByLodgingId?.[lodging.lodging.id]?.packageDetails
                  .pricing;

              const packageDiscount =
                packagePricingDetails?.totalPackageSavings.fiat.value ?? 0;

              const packageDiscountPercentage =
                packageDiscount && packagePricingDetails
                  ? (packageDiscount /
                      packagePricingDetails?.strikethroughTotalPrice.fiat
                        .value) *
                    100
                  : 0;

              trackEvent({
                eventName: VIEWED_PACKAGE_HOTEL_SHOP_MAP,
                properties: {
                  ...searchDistanceFromShopProperties?.properties,
                  package_discount: packageDiscount,
                  package_discount_percentage: packageDiscountPercentage,
                },
                encryptedProperties: [
                  ...searchDistanceFromShopProperties?.encryptedProperties,
                ],
              });
            }}
            map={
              <HotelShopSmallMap
                lodging={lodging}
                setShowExpandedHotelMap={setShowExpandedHotelMap}
                searchDistanceFromShopProperties={
                  searchDistanceFromShopProperties
                }
                lodgingPackage={packagesByLodgingId?.[lodging.lodging.id]}
              />
            }
          />
          <HotelShopExpandedMap
            lodging={lodging}
            showExpandedHotelMap={showExpandedHotelMap}
            setShowExpandedHotelMap={setShowExpandedHotelMap}
          />
        </Box>
        {lodgingData?.tripAdvisorReviews &&
          lodgingData?.tripAdvisorReviews?.comments?.length > 0 && (
            <Box className={clsx("section-wrapper")}>
              <Typography variant="h2">Customer Reviews</Typography>
              <HotelTripAdvisorReviews lodgingData={lodgingData} trim={1000} />
            </Box>
          )}
        {lodgingData?.checkInInstructions && fromDate && untilDate && (
          <Box className={clsx("section-wrapper")}>
            <Typography
              variant="h4"
              className="hotel-shop-check-in-instructions-heading"
            >
              {textConstants.CHECK_IN_INSTRUCTIONS_TITLE}
            </Typography>
            <Typography
              variant="body2"
              dangerouslySetInnerHTML={{
                __html: textConstants.formattedCheckIn(
                  fromDate,
                  lodgingData.checkInInstructions?.checkInTime
                ),
              }}
            />
            <Typography
              variant="body2"
              dangerouslySetInnerHTML={{
                __html: textConstants.formattedCheckOut(
                  untilDate,
                  lodgingData.checkInInstructions?.checkOutTime
                ),
              }}
            />
          </Box>
        )}
      </Box>
    );
  };

  const renderShop = () => {
    if (!lodging) {
      return null;
    }
    return (
      <>
        <div ref={ref}>
          <Box
            id="hotel-shop-room-type-section"
            className={clsx(
              "hotel-shop-room-type-section-wrapper-redesign",
              "white-bg"
            )}
          >
            <Typography className="choose-bed-type-header" variant="h2">
              Choose Room
            </Typography>
            <DesktopRoomPicker
              handleReadyToRedirect={handleReadyToRedirect}
              roomInfoProductsType={roomInfoProductsType}
              setRoomInfoProduct={setRoomInfoProduct}
              setRoomProductIndex={setRoomProductIndex}
            />
          </Box>
        </div>
      </>
    );
  };

  const [successShopSearchFromDate, setSuccessShopSearchFromDate] =
    useState<Date | null>(null);
  const [successShopSearchUntilDate, setSuccessShopSearchUntilDate] =
    useState<Date | null>(null);

  useEffect(() => {
    if (hotelShopCallState === PackageHotelShopCallState.Success) {
      setSuccessShopSearchFromDate(fromDate);
      setSuccessShopSearchUntilDate(untilDate);
    }
  }, [hotelShopCallState]);

  const returnToAvailability = (
    prevSuccessHotelFromDate?: Date | null,
    prevSuccessHotelUntilDate?: Date | null
  ) => {
    goToAvailability({
      history,
      origin,
      lodging,
      fromDate,
      untilDate,
      adultsCount,
      children,
      infants,
      stopsOption,
      prevSuccessHotelFromDate,
      prevSuccessHotelUntilDate,
      fareClassOptionFilter,
    });
  };

  return hasFailed ? (
    <ShopErrorModal
      returnToAvailability={() =>
        returnToAvailability(
          successShopSearchFromDate,
          successShopSearchUntilDate
        )
      }
    />
  ) : (
    <Box className="desktop-package-hotel-shop-wrapper">
      <DesktopRewardsHeader />
      <PackagesShopProgressBar />
      {renderAvailability()}
      {hotelShopCallInProgress ? renderSkeleton() : renderShop()}
    </Box>
  );
};
