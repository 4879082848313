import React from "react";
import { FiatPrice, RewardsAccount, RewardsPrice } from "redmond";
import { formatCurrency, formatRewards } from "../../state/utils";
import { Typography } from "@material-ui/core";

export const PACKAGE_BANNER_EARN_TEXT = (
  packageDiscount: FiatPrice | undefined,
  earn: RewardsPrice | undefined,
  selectedCardPaymentRewardsAccount: RewardsAccount | undefined
) => (
  <Typography className="package-discount-banner-content">
    {packageDiscount?.value ? (
      <>
        <strong>Save a total of {formatCurrency(packageDiscount)}</strong>
        {!!earn && !!selectedCardPaymentRewardsAccount ? " and" : ""}{" "}
      </>
    ) : (
      ""
    )}
    {!!earn && !!selectedCardPaymentRewardsAccount && (
      <>
        <strong>
          {packageDiscount?.value ? "e" : "E"}arn {formatRewards(earn)}
        </strong>{" "}
        with {selectedCardPaymentRewardsAccount.productDisplayName}{" "}
      </>
    )}
    when you book this hotel and flight package through Capital One Travel.
  </Typography>
);
