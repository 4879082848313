import { assign } from "xstate";
import { PackagesMachineContext } from "../types";
import { SelectPassengerEvent } from "../events";
import {
  cartHelpers,
  ContactSelectors,
  FlightPassengerSelectors,
  getObjectKeysAsObject,
  ParentState,
  setContextWithKey,
} from "@capone/checkout";
import { LodgingSelectors } from "../selectors";
import { Product, ProductOpaqueValue } from "@b2bportal/purchase-api";
import {
  BedTypeEnum,
  HotelQuoteScheduleRequest,
  HotelStarRatingEnum,
  LodgingCollectionEnum,
} from "redmond";
import dayjs from "dayjs";

export const LodgingActions = {
  selectPassenger: assign(
    (ctx: PackagesMachineContext, event: SelectPassengerEvent) => {
      const passengerId = event.data?.id || event.passengerId;

      const newPassengerInfoCtx = {
        ...ctx[ParentState.lodgingPassengerInformation],
      };

      newPassengerInfoCtx.selectedPassengerId = passengerId;

      return {
        ...ctx,
        [ParentState.lodgingPassengerInformation]: newPassengerInfoCtx,
      };
    }
  ),
  addLodgingProduct: assign(
    (context: PackagesMachineContext, _event: unknown) => {
      const selectedUserId = LodgingSelectors.getSelectedPassengerIdParent({
        context,
      });
      if (!selectedUserId) {
        throw new Error("No hotel guest selected, unable to add hotel product");
      }
      const selectedProduct = LodgingSelectors.getSelectedRoomProduct({
        context,
      });

      if (!selectedProduct) {
        throw new Error("No selected product");
      }

      if (!context.finalizePackageResponse) {
        throw new Error("finalizePackageResponse not populated");
      }

      const contactInformation = ContactSelectors.getContactInformation({
        context,
      });

      const fromDate = LodgingSelectors.getHotelFromDate({ context });
      const untilDate = LodgingSelectors.getHotelUntilDate({ context });
      const selectedLodging = LodgingSelectors.getSelectedLodging({ context });
      const roomInfo = LodgingSelectors.getSelectedRoom({ context });
      const flightPAX =
        FlightPassengerSelectors.getAllSelectedPassengerIdsParent({
          context,
        });

      const params: HotelQuoteScheduleRequest = {
        token: "00000000-0000-0000-0000-000000000000",
        quoteRequest:
          context.finalizePackageResponse?.lodgingOpaqueQuoteRequest,
        personId: selectedUserId,
        phoneNumber: contactInformation.phoneNumber,
        emailAddress: contactInformation.email,
        dates:
          fromDate && untilDate
            ? {
                from: dayjs(fromDate).format("YYYY-MM-DD"),
                until: dayjs(untilDate).format("YYYY-MM-DD"),
              }
            : undefined,
        id: selectedLodging?.lodging.id,
        name: selectedLodging?.lodging.name,
        city: selectedLodging?.lodging.city,
        country: selectedLodging?.lodging.country,
        starRating: selectedLodging?.lodging.starRating
          ? HotelStarRatingEnum[selectedLodging?.lodging.starRating]
          : undefined,
        providerName: selectedLodging?.price?.providerName,
        bedTypes: {
          description: "",
          ...roomInfo?.roomInfo.beds.bedTypes,
          bedTypes:
            roomInfo?.roomInfo.beds.bedTypes.map((bedType) => ({
              ...bedType,
              bedType: BedTypeEnum[bedType.bedType],
            })) || [],
        },
        lodgingCollection: LodgingCollectionEnum.NoCollection,
        additionalGuests: flightPAX.filter(
          (personId) => personId !== selectedUserId
        ),
      };

      const hotelProduct: ProductOpaqueValue = {
        type: Product.Hotel,
        value: params,
      };
      const ctxWithHotel = cartHelpers.addQuoteProduct(hotelProduct, context);

      return setContextWithKey(
        ctxWithHotel,
        `${ParentState.cartQuote}.customerEmail`,
        contactInformation?.email
      );
    }
  ),
};

export const LodgingActionTypes = getObjectKeysAsObject(LodgingActions);
